const TopStockIcon = (props: any) => {
  const { active } = props;
  return (
    <svg
      width={23}
      height={28}
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.68484 8.8C9.53343 8.79989 9.38414 8.76527 9.24888 8.6989C9.11362 8.63254 8.99613 8.53626 8.90579 8.41776C8.81544 8.29926 8.75473 8.1618 8.72851 8.01636C8.70229 7.87092 8.71127 7.72151 8.75475 7.58005L9.4378 5.36109L7.9638 4.21062C7.80677 4.08802 7.69266 3.92089 7.63722 3.73232C7.58178 3.54374 7.58776 3.34302 7.65432 3.1579C7.72088 2.97278 7.84474 2.81239 8.0088 2.69889C8.17285 2.58538 8.369 2.52437 8.57013 2.52429H10.2501L11.1073 0.574044C11.1821 0.403661 11.3065 0.258442 11.4652 0.156397C11.6238 0.0543521 11.8097 -2.8533e-05 11.9997 1.12313e-08C12.1897 2.85555e-05 12.3756 0.054465 12.5342 0.156558C12.6928 0.25865 12.8172 0.403907 12.8919 0.574312L13.7491 2.52429H15.4291C15.6303 2.52436 15.8265 2.58538 15.9905 2.69893C16.1546 2.81248 16.2785 2.97293 16.345 3.15812C16.4115 3.34331 16.4174 3.54408 16.3619 3.73267C16.3063 3.92126 16.1921 4.08837 16.0349 4.21088L14.5614 5.3611L15.2445 7.58033C15.3033 7.77128 15.2987 7.97546 15.2316 8.16377C15.1644 8.35207 15.038 8.51489 14.8704 8.62902C14.7028 8.74315 14.5025 8.80276 14.2981 8.79936C14.0937 8.79595 13.8957 8.72972 13.7321 8.61008L11.9996 7.34313L10.2671 8.61035C10.0992 8.73347 9.89488 8.80003 9.68484 8.8ZM11.9996 6.21348C12.2095 6.21308 12.4138 6.27917 12.5819 6.4018L14.0384 7.46654L13.4578 5.58014C13.4014 5.39909 13.4023 5.20569 13.4602 5.02513C13.5182 4.84456 13.6305 4.68519 13.7826 4.56775L14.9986 3.6184H13.6495C13.4594 3.61856 13.2735 3.56419 13.1148 3.46208C12.9562 3.35997 12.8318 3.21461 12.7572 3.04409L11.9996 1.32064L11.2421 3.04436C11.1674 3.21481 11.043 3.3601 10.8844 3.46216C10.7258 3.56422 10.5399 3.61855 10.3498 3.6184H9.00069L10.2178 4.56855C10.3696 4.68608 10.4816 4.84542 10.5394 5.02587C10.5971 5.20632 10.5979 5.39953 10.5415 5.5804L9.9609 7.46654L11.4174 6.40153C11.5855 6.2791 11.7898 6.21311 11.9996 6.21348Z"
        fill={active ? '#F2BB0E' : 'white'}
      />
      <mask id="path-2-inside-1_2695_5398" fill="white">
        <path d="M0 18.6C0 18.0477 0.447715 17.6 1 17.6H5.6C6.15229 17.6 6.6 18.0477 6.6 18.6V26.5C6.6 27.0523 6.15228 27.5 5.6 27.5H0.999999C0.447714 27.5 0 27.0523 0 26.5V18.6Z" />
      </mask>
      <path
        d="M0 18.6C0 18.0477 0.447715 17.6 1 17.6H5.6C6.15229 17.6 6.6 18.0477 6.6 18.6V26.5C6.6 27.0523 6.15228 27.5 5.6 27.5H0.999999C0.447714 27.5 0 27.0523 0 26.5V18.6Z"
        stroke={active ? '#F2BB0E' : 'white'}
        strokeWidth="2.4"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_2695_5398)"
      />
      <mask id="path-3-inside-2_2695_5398" fill="white">
        <path d="M16.4004 15.3C16.4004 14.7477 16.8481 14.3 17.4004 14.3H22.0004C22.5527 14.3 23.0004 14.7477 23.0004 15.3V26.5C23.0004 27.0523 22.5527 27.5 22.0004 27.5H17.4004C16.8481 27.5 16.4004 27.0523 16.4004 26.5V15.3Z" />
      </mask>
      <path
        d="M16.4004 15.3C16.4004 14.7477 16.8481 14.3 17.4004 14.3H22.0004C22.5527 14.3 23.0004 14.7477 23.0004 15.3V26.5C23.0004 27.0523 22.5527 27.5 22.0004 27.5H17.4004C16.8481 27.5 16.4004 27.0523 16.4004 26.5V15.3Z"
        stroke={active ? '#F2BB0E' : 'white'}
        strokeWidth="2.4"
        strokeLinejoin="round"
        mask="url(#path-3-inside-2_2695_5398)"
      />
      <mask id="path-4-inside-3_2695_5398" fill="white">
        <path d="M8.7002 12C8.7002 11.4477 9.14791 11 9.7002 11H14.3002C14.8525 11 15.3002 11.4477 15.3002 12V26.5C15.3002 27.0523 14.8525 27.5 14.3002 27.5H9.70019C9.14791 27.5 8.7002 27.0523 8.7002 26.5V12Z" />
      </mask>
      <path
        d="M8.7002 12C8.7002 11.4477 9.14791 11 9.7002 11H14.3002C14.8525 11 15.3002 11.4477 15.3002 12V26.5C15.3002 27.0523 14.8525 27.5 14.3002 27.5H9.70019C9.14791 27.5 8.7002 27.0523 8.7002 26.5V12Z"
        stroke={active ? '#F2BB0E' : 'white'}
        strokeWidth="2.4"
        strokeLinejoin="round"
        mask="url(#path-4-inside-3_2695_5398)"
      />
    </svg>
  );
};

export default TopStockIcon;

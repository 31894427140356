import {
  Badge,
  BadgeProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  // Tooltip,
} from '@mui/material';
// import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { configColors } from '../../const/colors';
import { LightTooltip } from './LightTooltip';

interface AppMenuItemProps {
  title: string;
  icon: any;
  active?: boolean;
  path: string;
  open: boolean;
  badge?: string;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: configColors.styledBadge.background,
    color: configColors.styledBadge.color,
    // transform: 'translate(60px, -15px)',
    borderRadius: 3,
  },
}));

export const AppMenuItem: React.FC<AppMenuItemProps> = ({
  title,
  icon,
  active,
  path,
  open,
  badge,
}) => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const Icon: any = icon;
  // const AppBadge: any = badge;
  return (
    <div style={{ position: 'relative' }}>
      <ListItem key={title} disablePadding sx={{ display: 'block' }}>
        <LightTooltip title={title} placement="right-end">
          <ListItemButton
            sx={{
              minHeight: 50,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={() => navigate(`/${path}`)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Icon active={active ? 1 : 0} />
            </ListItemIcon>
            {open && (
              <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            )}
          </ListItemButton>
        </LightTooltip>
      </ListItem>
      {badge ? (
        <StyledBadge
          color="primary"
          badgeContent={badge}
          style={{
            position: 'absolute',
            top: 10,
            right: 5,
            pointerEvents: 'none',
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';

export interface CGTopRatingProps {
  data: Array<CGRSData>;
  loadData: () => void;
  loading: boolean;
  type: number;
  setType: (type: number) => void;
  filter: IFilter;
  updateFiltes: (filter: IFilter) => void;
  ratingFilter: string;
  updateRatingFilter: (ratingFilter: string) => void;
  updateData: (data: any) => void;
}

export interface CGRSData {
  code: string;
  adClose: number;
  pctChange: number;
  nmVolume: number;
  rating: number;
}

export interface IFilter {
  adCloseFrom: number;
  adCloseTo: number;
  valFrom: number;
  valTo: number;
  capFrom: number;
  capTo: number;
}

class CGTopRatingStore implements CGTopRatingProps {
  @observable
  type: number = 1;

  private apiPath: string[] = [
    '/api/top/rsrating',
    '/api/top/epsrating',
    '/api/top/smrrating',
    '/api/top/rsnrating',
  ];

  @observable
  data: Array<CGRSData> = [];

  @observable
  loading: boolean = true;

  @observable
  filter: IFilter = {
    adCloseFrom: 10,
    adCloseTo: 500,
    valFrom: 100,
    valTo: 200000,
    capFrom: 1000,
    capTo: 200000,
  };

  constructor() {
    makeAutoObservable(this);
  }
  @observable
  ratingFilter: string = '';

  @action
  public updateRatingFilter = (ratingFilter: string) => {
    this.ratingFilter = ratingFilter;
    this.loadData();
  };

  @action
  public setType = (type: number) => {
    this.type = type;
  };

  @action
  public updateFiltes = async (filter: IFilter) => {
    this.filter = filter;
    this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    let queryString: string = `${this.apiPath[this.type - 1]}?adclosefrom=${
      this.filter.adCloseFrom
    }&adcloseto=${this.filter.adCloseTo}&valfrom=${this.filter.valFrom}&valto=${
      this.filter.valTo
    }&capfrom=${this.filter.capFrom}&capto=${this.filter.capTo}&type=${
      this.ratingFilter
    }`;
    if (this.type === 4) queryString = `${this.apiPath[this.type - 1]}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public updateData = (data: any[]) => {
    this.data = data;
  };
}

export default CGTopRatingStore;

import Box from '@mui/material/Box';

const flagPair: {[key: string]: string} = {
  "NYSE": "flags_us.svg",
  "NASDAQ": "flags_us.svg",
  "CME": "flags_us.svg",
  "London": "flags_gb.svg",
  "Đức": "flags_de.svg",
  "Pháp": "flags_fr.svg",
  "Nga": "flags_ru.svg",
  "Trung Quốc": "flags_cn.svg",
  "Nhật Bản": "flags_jp.svg",
  "Hàn Quốc": "flags_kr.svg",
  "Đài Loan": "flags_tw.svg",
  "HongKong": "flags_hk.svg",
  "Ấn Độ": "flags_in.svg",
};

export const AppFlag: React.FC<{ floor: string }> = ({ floor }) => {
  if (flagPair[floor])
    return (
      <Box
        sx={{
          height: 10,
          width: 15,
          marginRight: '7px',
          backgroundImage: 'url(../assets/images/floor)'.replace(
            'floor',
            flagPair[floor],
          ),
        }}
      />
    );
  else return <></>;
};

import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export const DataPeroidTypes = [50, 100, 200];
export const FloorTypes = ['all', 'HOSE', 'VN30', 'HNX', 'HNX30', 'UPCOM'];

export interface TopStockProps {
  generalData: GeneralData[];
  financeData: FinanceData[];
  techniqueData: TechniqueData[];
  loading: boolean;
  activeTab: number;
  limit: number;
  setActiveTab: (type: number) => void;
  setTabStatus: (param: string, data: number) => void;
  loadContentData: () => void;
  updateData: (
    generalData: any[],
    financeData: any[],
    techniqueData: any[],
  ) => void;
}

export interface GeneralData {
  code: string;
  adClose: number;
  pctChange: number;
  nmVolume: number;
  volMa20: number;
  MarketCapital: number;
  PE: number;
  PB: number;
}

export interface FinanceData {
  code: string;
  pctChange: number;
  nmVolume: number;
  nmValue: number;
  MarketCapital: number;
  quarter: string;
  sector: string;
  ESP: number;
  revenue: number;
  growthRevenue: number;
  profit: number;
  growthProfit: number;
  ROE: number;
  ROA: number;
  RsRating: number;
}

export interface TechniqueData {
  code: string;
  pctChange: number;
  nmVolume: number;
  nmValue: number;
  MarketCapital: number;
  sector: string;
  volMa20: number;
  ma20: number;
  ma50: number;
  ma200: number;
}

class TopStockStore implements TopStockProps {
  private apiPath: string = 'api/top';

  @observable
  generalData: GeneralData[] = [];

  @observable
  financeData: FinanceData[] = [];

  @observable
  techniqueData: TechniqueData[] = [];

  @observable
  loading: boolean = false;

  @observable
  activeTab: number = 0;

  @observable
  cat: number = 1;

  @observable
  floor: number = 0;

  @observable
  type: number = 1;

  @observable
  limit: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadContentData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}?cat=${this.cat}&type=${
      this.type
    }&limit=${this.limit}&floor=${FloorTypes[this.floor]}`; //
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public setTabStatus = (param: string, data: number) => {
    if (param === 'cat') this.cat = data;
    else if (param === 'floor') this.floor = data;
    else if (param === 'type') this.type = data;
    else if (param === 'limit') this.limit = data;
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    if (this.type === 1) this.generalData = dataR;
    else if (this.type === 2) this.financeData = dataR;
    else if (this.type === 3) this.techniqueData = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public updateData = (
    generalData: any[],
    financeData: any[],
    techniqueData: any[],
  ) => {
    this.generalData = generalData;
    this.financeData = financeData;
    this.techniqueData = techniqueData;
  };

  @action
  public setActiveTab = (tabIndex: number) => {
    this.activeTab = tabIndex;
  };
}

export default new TopStockStore();

import { observable, action, makeAutoObservable } from 'mobx';
import { Global2kData } from './globalStore';
import api from './request';
import WSClient, { WSClientProps } from './wsClient';

export interface InvestmentStrategyStoreProps {
  dataAccumulation: Array<IData>;
  dataBreaking: Array<IData>;
  dataOvercomingMA: Array<IData>;
  filterAccumulation: FilterAccumulation;
  filterBreaking: FilterBreaking;
  filterOvercomingMA: FilterOvercomingMA;
  loading: boolean;
  tabActiveIndex: number;
  setTabActiveIndex: (tabActiveIndex: number) => void;
  updateFilterAccumulation: (filterAccumulation: FilterAccumulation) => void;
  updateFilterBreaking: (filterBreaking: FilterBreaking) => void;
  updateFilterOvercomingMA: (filterOvercomingMA: FilterOvercomingMA) => void;
  fetchAccumulatio: () => void;
  fetchBreaking: () => void;
  fetchOvercomingMA: () => void;
  startWebSocket: () => void;
  endWebSocket: () => void;
}

export interface IData {
  adChange: string;
  adClose: string;
  code: string;
  nmVolume: string;
  pctChange: string;
  rsRating: string;
  volMa20: string;
  statusAdClose?: 0 | 1 | 2;
  statusPctChange?: 0 | 1 | 2;
  statusNmVolume?: 0 | 1 | 2;
}

export interface FilterOvercomingMA {
  over: string;
  floor: 0 | 1 | 2 | 3 | 4 | 5;
  adClose: 0 | 1 | 2 | 3;
  customAdClose?: string;
  volMa20: 0 | 1 | 2 | 3;
  customVolMa20?: string;
  ma: 1 | 2 | 3;
  pctChange: 0 | 1 | 2 | 3 | 4;
  x: 1 | 2 | 3 | 4;
}

export interface FilterBreaking {
  over: string;
  floor: 0 | 1 | 2 | 3 | 4 | 5;
  adClose: 0 | 1 | 2 | 3;
  customAdClose?: string;
  volMa20: 0 | 1 | 2 | 3;
  customVolMa20?: string;
  month: 1 | 2 | 3 | 4;
  x: 1 | 2 | 3 | 4;
}

export interface FilterAccumulation {
  floor: 0 | 1 | 2 | 3 | 4 | 5;
  adClose: 0 | 1 | 2 | 3;
  customAdClose?: string;
  volMa20: 0 | 1 | 2 | 3;
  customVolMa20?: string;
  day: 1 | 2 | 3 | 4;
  pct: 1 | 2 | 3 | 4;
}

class InvestmentStrategyStore implements InvestmentStrategyStoreProps {
  private apiPathByAdClose: string = 'api/strategy/byAdClose';
  private apiPathbyHighLow: string = 'api/strategy/byHighLow';
  private apiPathbyMa: string = 'api/strategy/byMa';
  private echoChannel: string = 'AllStockData';
  private wsClient: WSClientProps = WSClient;
  private dataList: Array<Global2kData> = [];

  @observable
  dataBreaking: Array<IData> = [];

  @observable
  dataAccumulation: Array<IData> = [];

  @observable
  dataOvercomingMA: Array<IData> = [];

  @observable
  filterOvercomingMA: FilterOvercomingMA = {
    over: '1',
    floor: 0,
    adClose: 1,
    volMa20: 1,
    ma: 1,
    pctChange: 0,
    x: 3,
  };

  @observable
  filterBreaking: FilterBreaking = {
    over: '1',
    floor: 0,
    adClose: 1,
    volMa20: 1,
    month: 1,
    x: 1,
  };

  @observable
  filterAccumulation: FilterAccumulation = {
    floor: 0,
    adClose: 1,
    volMa20: 1,
    day: 1,
    pct: 1,
  };

  @observable
  tabActiveIndex: number = 0;

  @observable
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.dataList = [];
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  private fetchDataSocketSuccess = (data: {
    end: 0 | 1;
    data: Array<Global2kData>;
  }) => {
    if (!data.end) {
      this.dataList = [...this.dataList, ...data.data];
    }
    if (data.end) {
      // const dataTmp: Array<Global2kData> = [...this.dataList, ...data.data];
      this.dataList = [];
    }
    this.updateTopListData(data.data);
  };

  @action
  private updateTopListData = (data2K: Array<Global2kData>) => {
    const { dataAccumulation, dataBreaking, dataOvercomingMA } = this;
    if (
      this.tabActiveIndex === 0 &&
      dataAccumulation.length > 0 &&
      data2K.length > 0
    ) {
      let dataTMP = dataAccumulation.slice();
      let isUpdate = false;
      for (let index = 0; index < dataTMP.length; index++) {
        const element = dataAccumulation[index];
        const itemChange = data2K.find((t) => t.code === element.code);
        if (itemChange) {
          dataTMP[index] = {
            ...dataTMP[index],
            ...itemChange,
            adClose: itemChange.adClose.toString(),
            pctChange: itemChange.pctChange.toString(),
            nmVolume: itemChange.nmVolume.toString(),
            statusAdClose:
              parseFloat(itemChange.adClose) >
              parseFloat(dataTMP[index].adClose.toString())
                ? 1
                : parseFloat(itemChange.adClose) <
                  parseFloat(dataTMP[index].adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(itemChange.pctChange) >
              parseFloat(dataTMP[index].pctChange.toString())
                ? 1
                : parseFloat(itemChange.pctChange) <
                  parseFloat(dataTMP[index].pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(itemChange.nmVolume) >
              parseFloat(dataTMP[index].nmVolume.toString())
                ? 1
                : parseFloat(itemChange.nmVolume) <
                  parseFloat(dataTMP[index].nmVolume.toString())
                ? 2
                : 0,
          };
          isUpdate = true;
        }
      }
      if (isUpdate) {
        this.dataAccumulation = dataTMP;
      }
    }
    if (
      this.tabActiveIndex === 1 &&
      dataBreaking.length > 0 &&
      data2K.length > 0
    ) {
      let dataTMP = dataBreaking.slice();
      let isUpdate = false;
      for (let index = 0; index < dataTMP.length; index++) {
        const element = dataBreaking[index];
        const itemChange = data2K.find((t) => t.code === element.code);
        if (itemChange) {
          dataTMP[index] = {
            ...dataTMP[index],
            ...itemChange,
            adClose: itemChange.adClose.toString(),
            pctChange: itemChange.pctChange.toString(),
            nmVolume: itemChange.nmVolume.toString(),
            statusAdClose:
              parseFloat(itemChange.adClose) >
              parseFloat(dataTMP[index].adClose.toString())
                ? 1
                : parseFloat(itemChange.adClose) <
                  parseFloat(dataTMP[index].adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(itemChange.pctChange) >
              parseFloat(dataTMP[index].pctChange.toString())
                ? 1
                : parseFloat(itemChange.pctChange) <
                  parseFloat(dataTMP[index].pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(itemChange.nmVolume) >
              parseFloat(dataTMP[index].nmVolume.toString())
                ? 1
                : parseFloat(itemChange.nmVolume) <
                  parseFloat(dataTMP[index].nmVolume.toString())
                ? 2
                : 0,
          };
          isUpdate = true;
        }
      }
      if (isUpdate) {
        this.dataBreaking = dataTMP;
      }
    }
    if (
      this.tabActiveIndex === 2 &&
      dataOvercomingMA.length > 0 &&
      data2K.length > 0
    ) {
      let dataTMP = dataOvercomingMA.slice();
      let isUpdate = false;
      for (let index = 0; index < dataTMP.length; index++) {
        const element = dataOvercomingMA[index];
        const itemChange = data2K.find((t) => t.code === element.code);
        if (itemChange) {
          dataTMP[index] = {
            ...dataTMP[index],
            ...itemChange,
            adClose: itemChange.adClose.toString(),
            pctChange: itemChange.pctChange.toString(),
            nmVolume: itemChange.nmVolume.toString(),
            statusAdClose:
              parseFloat(itemChange.adClose) >
              parseFloat(dataTMP[index].adClose.toString())
                ? 1
                : parseFloat(itemChange.adClose) <
                  parseFloat(dataTMP[index].adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(itemChange.pctChange) >
              parseFloat(dataTMP[index].pctChange.toString())
                ? 1
                : parseFloat(itemChange.pctChange) <
                  parseFloat(dataTMP[index].pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(itemChange.nmVolume) >
              parseFloat(dataTMP[index].nmVolume.toString())
                ? 1
                : parseFloat(itemChange.nmVolume) <
                  parseFloat(dataTMP[index].nmVolume.toString())
                ? 2
                : 0,
          };
          isUpdate = true;
        }
      }
      if (isUpdate) {
        this.dataOvercomingMA = dataTMP;
      }
    }
  };

  @action
  fetchOvercomingMA = () => {
    this.loading = true;
    const {
      over,
      floor,
      adClose,
      customAdClose,
      volMa20,
      customVolMa20,
      pctChange,
      x,
      ma,
    } = this.filterOvercomingMA;
    const cdClose = customAdClose ? customAdClose : '';
    const cVolMa20 = customVolMa20 ? customVolMa20 : '';
    let adCloseP = adClose;
    let volMa20P = volMa20;
    if (!customAdClose && adClose === 3) {
      adCloseP = 0;
      this.filterOvercomingMA = { ...this.filterOvercomingMA, adClose: 0 };
    }
    if (!customVolMa20 && volMa20 === 3) {
      volMa20P = 0;
      this.filterOvercomingMA = { ...this.filterOvercomingMA, volMa20: 0 };
    }
    const queryString: string = `${this.apiPathbyMa}?over=${over}&floor=${floor}&adClose=${adCloseP}&customAdClose=${cdClose}&volMa20=${volMa20P}&customVolMa20=${cVolMa20}&pctChange=${pctChange}&ma=${ma}&x=${x}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataOvercomingMASuccess)
      .catch(this.fetchDataError);
  };

  private fetchDataOvercomingMASuccess = (dataR: any) => {
    this.dataOvercomingMA = dataR.map((k: any) => ({
      ...k,
      adChange: k.adChange.toString(),
      adClose: k.adClose.toString(),
      nmVolume: k.nmVolume.toString(),
      pctChange: k.pctChange.toString(),
      volMa20: k.volMa20.toString(),
    }));
    this.loading = false;
  };

  @action
  fetchBreaking = () => {
    this.loading = true;
    const {
      over,
      floor,
      adClose,
      customAdClose,
      volMa20,
      customVolMa20,
      month,
      x,
    } = this.filterBreaking;
    const cdClose = customAdClose ? customAdClose : '';
    const cVolMa20 = customVolMa20 ? customVolMa20 : '';
    let adCloseP = adClose;
    let volMa20P = volMa20;
    if (!customAdClose && adClose === 3) {
      adCloseP = 0;
      this.filterBreaking = { ...this.filterBreaking, adClose: 0 };
    }
    if (!customVolMa20 && volMa20 === 3) {
      volMa20P = 0;
      this.filterBreaking = { ...this.filterBreaking, volMa20: 0 };
    }
    const queryString: string = `${this.apiPathbyHighLow}?over=${over}&floor=${floor}&adClose=${adCloseP}&customAdClose=${cdClose}&volMa20=${volMa20P}&customVolMa20=${cVolMa20}&month=${month}&pct=${x}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataBreakingSuccess)
      .catch(this.fetchDataError);
  };

  private fetchDataBreakingSuccess = (dataR: any) => {
    this.dataBreaking = dataR.map((k: any) => ({
      ...k,
      adChange: k.adChange.toString(),
      adClose: k.adClose.toString(),
      nmVolume: k.nmVolume.toString(),
      pctChange: k.pctChange.toString(),
      volMa20: k.volMa20.toString(),
    }));
    this.loading = false;
  };

  @action
  fetchAccumulatio = () => {
    this.loading = true;
    const { floor, adClose, customAdClose, volMa20, customVolMa20, day, pct } =
      this.filterAccumulation;
    const cdClose = customAdClose ? customAdClose : '';
    const cVolMa20 = customVolMa20 ? customVolMa20 : '';
    let adCloseP = adClose;
    let volMa20P = volMa20;
    if (!customAdClose && adClose === 3) {
      adCloseP = 0;
      this.filterAccumulation = { ...this.filterAccumulation, adClose: 0 };
    }
    if (!customVolMa20 && volMa20 === 3) {
      volMa20P = 0;
      this.filterAccumulation = { ...this.filterAccumulation, volMa20: 0 };
    }
    const queryString: string = `${this.apiPathByAdClose}?floor=${floor}&adClose=${adCloseP}&customAdClose=${cdClose}&volMa20=${volMa20P}&customVolMa20=${cVolMa20}&day=${day}&pct=${pct}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataAccumulatioSuccess)
      .catch(this.fetchDataError);
  };

  private fetchDataAccumulatioSuccess = (dataR: any) => {
    this.dataAccumulation = dataR.map((k: any) => ({
      ...k,
      adChange: k.adChange.toString(),
      adClose: k.adClose.toString(),
      nmVolume: k.nmVolume.toString(),
      pctChange: k.pctChange.toString(),
      volMa20: k.volMa20.toString(),
    }));
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    this.loading = false;
    console.error(error);
  };

  @action
  setTabActiveIndex = (tabActiveIndex: number) => {
    this.tabActiveIndex = tabActiveIndex;
  };

  @action
  updateFilterOvercomingMA = (filterOvercomingMA: FilterOvercomingMA) => {
    this.filterOvercomingMA = filterOvercomingMA;
  };

  @action
  updateFilterBreaking = (filterBreaking: FilterBreaking) => {
    this.filterBreaking = filterBreaking;
  };

  @action
  updateFilterAccumulation = (filterAccumulation: FilterAccumulation) => {
    this.filterAccumulation = filterAccumulation;
  };
}

export default new InvestmentStrategyStore();

const AppHomeIcon = (props: any) => {
  const { active } = props;
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.0844 9.06899L16.1833 1.48299C15.2055 0.546978 13.8809 0.0213623 12.5 0.0213623C11.1191 0.0213623 9.79455 0.546978 8.81668 1.48299L0.915638 9.06899C0.624414 9.34677 0.393523 9.67729 0.236349 10.0414C0.0791742 10.4055 -0.00115667 10.7959 1.25828e-05 11.19V21.007C1.25828e-05 21.8026 0.329253 22.5657 0.915304 23.1283C1.50136 23.6909 2.29621 24.007 3.12501 24.007H21.875C22.7038 24.007 23.4987 23.6909 24.0847 23.1283C24.6708 22.5657 25 21.8026 25 21.007V11.19C25.0012 10.7959 24.9209 10.4055 24.7637 10.0414C24.6065 9.67729 24.3756 9.34677 24.0844 9.06899ZM15.625 22.007H9.37501V18.073C9.37501 17.2773 9.70425 16.5143 10.2903 15.9517C10.8764 15.3891 11.6712 15.073 12.5 15.073C13.3288 15.073 14.1237 15.3891 14.7097 15.9517C15.2958 16.5143 15.625 17.2773 15.625 18.073V22.007ZM22.9167 21.007C22.9167 21.2722 22.8069 21.5266 22.6116 21.7141C22.4162 21.9016 22.1513 22.007 21.875 22.007H17.7083V18.073C17.7083 16.7469 17.1596 15.4751 16.1829 14.5375C15.2061 13.5998 13.8813 13.073 12.5 13.073C11.1187 13.073 9.79392 13.5998 8.81717 14.5375C7.84041 15.4751 7.29168 16.7469 7.29168 18.073V22.007H3.12501C2.84875 22.007 2.58379 21.9016 2.38844 21.7141C2.19309 21.5266 2.08335 21.2722 2.08335 21.007V11.19C2.08431 10.925 2.19396 10.671 2.38855 10.483L10.2896 2.89999C10.8768 2.33892 11.6715 2.02393 12.5 2.02393C13.3285 2.02393 14.1232 2.33892 14.7104 2.89999L22.6115 10.486C22.8053 10.6732 22.9149 10.926 22.9167 11.19V21.007Z"
        fill={active ? '#F2BB0E' : 'white'}
      />
    </svg>
  );
};

export default AppHomeIcon;

import { formatLocale, FormatLocaleDefinition } from 'd3-format';

const jaDefinition: FormatLocaleDefinition = {
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['vnd', ''],
};
const localFormatter = formatLocale(jaDefinition);

export const covertNumber = (str: number) => {
  if (!Number(str) && str !== 0) {
    return localFormatter.format(',.2f')(0);
  }
  return localFormatter.format(',.2f')(str);
};

export const covertNumberWithDash = (str: number) => {
  if (!Number(str) && str !== 0) {
    return '-';
  }
  return localFormatter.format(',.2f')(str);
};

export const covertFNumber = (str: number, f: number) => {
  if (!Number(str) && str !== 0) {
    return localFormatter.format(',.2f')(0);
  }
  return localFormatter.format(`,.${f}f`)(str);
};

export const covert2Number = (str: number) => {
  if (!Number(str) && str !== 0) {
    return localFormatter.format(',.2f')(0);
  }
  return localFormatter.format(',.2f')(str);
};

export const covertRightNumber = (str: number) => {
  return localFormatter.format(',.0f')(str);
};

export const covertNumbertoPer = (str: number, f?: number) => {
  if (!f) {
    f = 1;
  }
  if (!Number(str) && str !== 0) {
    return localFormatter.format(',.2f')(0) + '%';
  }
  return localFormatter.format(`,.${f}f`)(str) + '%';
};

export const covertNumberToInt = (str: number) => {
  if (!Number(str) && str !== 0) {
    return '-';
  }
  return localFormatter.format(',.0f')(str);
};

export const covertNumberToThoundsion = (str: number) => {
  if (!Number(str) && str !== 0) {
    return '-';
  }
  return localFormatter.format('.2s')(str);
};

export const covertNumberToMilions = (
  str: number,
  f: number = 0,
  withSuffix: boolean = true,
  zeroTo: boolean = false,
) => {
  if (zeroTo && str === 0) return '-';
  return withSuffix
    ? localFormatter.format(`,.${f}f`)(str / 1e6) + 'M'
    : localFormatter.format(`,.${f}f`)(str / 1e6);
};

export const covertNumberToBillions = (str: number, f?: number) => {
  if (!f) {
    f = 0;
  }
  return localFormatter.format(`,.${f}f`)(str / 1e9) + 'T';
};

export const covertNumberToBillionsWithoutUnit = (
  str: number,
  f?: number,
  zeroTo: boolean = false,
) => {
  if (zeroTo && str === 0) return '-';
  if (!f) {
    f = 0;
  }
  return localFormatter.format(`,.${f}f`)(str / 1e9);
};

export const covertNumberToCorrectBillions = (str: number, f?: number) => {
  if (!f) {
    f = 0;
  }
  if (Math.abs(str) < 1e9) return covertNumberToInt(str);
  return localFormatter.format(`,.${f}f`)(str / 1e9) + 'T';
};

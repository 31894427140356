const AppRRGIcon = (props: any) => {
  const { active } = props;
  return (
    <svg
      width={31}
      height={30}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_142_812)">
        <path
          d="M13.2704 0.17394C13.6816 -0.121367 14.2575 -0.0315943 14.5566 0.374454L17.0121 3.70779C17.1637 3.91368 17.2151 4.16135 17.1753 4.394C17.2151 4.62665 17.1637 4.87433 17.0121 5.08022L14.5566 8.41355C14.2575 8.8196 13.6816 8.90937 13.2704 8.61407C12.8591 8.31876 12.7682 7.7502 13.0673 7.34415L14.4592 5.45455H7.36634C6.34926 5.45455 5.52475 6.26857 5.52475 7.27273V10.9091C5.52475 11.4112 5.1125 11.8182 4.60396 11.8182C4.09542 11.8182 3.68317 11.4112 3.68317 10.9091V7.27273C3.68317 5.26442 5.33218 3.63636 7.36634 3.63636H14.6823L13.0673 1.44386C12.7682 1.03781 12.8591 0.469248 13.2704 0.17394Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M18.0367 29.8261C17.6254 30.1214 17.0495 30.0316 16.7504 29.6255L14.295 26.2922C14.1434 26.0865 14.092 25.8391 14.1316 25.6066C14.1245 25.5649 14.1203 25.5222 14.1191 25.4787C14.1167 25.3916 14.1269 25.3039 14.1502 25.2183C14.1807 25.1063 14.2323 25.0028 14.3005 24.9123L16.7504 21.5864C17.0495 21.1804 17.6254 21.0906 18.0367 21.3859C18.4479 21.6812 18.5389 22.2498 18.2398 22.6559L16.8478 24.5455H23.6337C24.6507 24.5455 25.4752 23.7314 25.4752 22.7273V19.0909C25.4752 18.5888 25.8875 18.1818 26.396 18.1818C26.9046 18.1818 27.3168 18.5888 27.3168 19.0909V22.7273C27.3168 24.7356 25.6678 26.3636 23.6337 26.3636H16.6247L18.2398 28.5561C18.5389 28.9622 18.4479 29.5308 18.0367 29.8261Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M30.6207 14.3716C31.032 14.0763 31.1229 13.5078 30.8238 13.1017C30.5247 12.6957 29.9488 12.6059 29.5376 12.9012L27.3168 14.4958V7.27273C27.3168 5.26442 25.6678 3.63636 23.6337 3.63636H19.9505C19.442 3.63636 19.0297 4.04338 19.0297 4.54545C19.0297 5.04753 19.442 5.45455 19.9505 5.45455H23.6337C24.6507 5.45455 25.4752 6.26857 25.4752 7.27273V14.2755L23.5613 12.9012C23.15 12.6059 22.5742 12.6957 22.2751 13.1017C21.9759 13.5078 22.0669 14.0763 22.4782 14.3716L25.8544 16.7959C26.0629 16.9456 26.3138 16.9964 26.5494 16.957C26.7851 16.9964 27.0359 16.9456 27.2445 16.7959L30.6207 14.3716Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M0.176179 17.2015C-0.122929 16.7954 -0.0320009 16.2268 0.379273 15.9315L3.75551 13.5073C3.96384 13.3577 4.2144 13.3069 4.44984 13.346C4.49995 13.3377 4.55144 13.3333 4.60396 13.3333C4.61242 13.3333 4.62085 13.3334 4.62925 13.3337C4.66529 13.3346 4.70134 13.3376 4.73722 13.3428C4.89264 13.365 5.03548 13.4256 5.1556 13.5145L8.52185 15.9315C8.93312 16.2268 9.02405 16.7954 8.72494 17.2015C8.42583 17.6075 7.84995 17.6973 7.43868 17.402L5.52475 16.0277V22.7271C5.52475 23.7313 6.34926 24.5453 7.36634 24.5453H11.0495C11.558 24.5453 11.9703 24.9525 11.9703 25.4545C11.9703 25.9566 11.558 26.3636 11.0495 26.3636L11.0327 26.3635H7.36634C5.33218 26.3635 3.68317 24.7354 3.68317 22.7271V15.8074L1.46244 17.402C1.05117 17.6973 0.475288 17.6075 0.176179 17.2015Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_142_812">
          <rect width={31} height={30} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppRRGIcon;

import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';

export interface CorrelationGraphChartProps {
  dataGraph: {
    name: string;
    data: Array<GraphChart>;
  };
  code: string;
  loadData: () => void;
  setCode: (code: string, loadData?: boolean) => void;
  updateData: (data: Array<GraphChart>) => void;
  loading: boolean;
  dataSocket?: GraphChart;
  updateDataSocket: (dataSocket?: GraphChart) => void;
}

export interface GraphChart {
  date: Date;
  open: number;
  low: number;
  high: number;
  close: number;
  volume: number;
  pctChange: number;
  rsLine: number;
  rsRating: number;
  change: number;
}

class CorrelationGraphChartStore implements CorrelationGraphChartProps {
  private apiPath: string = 'api/company/chart';

  @observable
  loading: boolean = true;

  @observable
  dataGraph: {
    name: string;
    data: Array<GraphChart>;
  } = {
    name: '',
    data: [],
  };

  @observable
  code: string = 'MBS';

  @observable
  dataSocket?: GraphChart;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadData();
  };

  @action
  public updateDataSocket = (dataSocket?: GraphChart) => {
    this.dataSocket = dataSocket;
  };

  @action
  public updateData = (data: Array<GraphChart>) => {
    this.dataGraph = { ...this.dataGraph, data };
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}?code=${this.code}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    if (dataR.length > 2) {
      let data: Array<GraphChart> = [];
      dataR[0].data.forEach((element: any) => {
        const rsLineData = dataR[1].data.find(
          (k: any) => k.date === element.date,
        );
        const rsRatingData = dataR[2].data.find(
          (k: any) => k.date === element.date,
        );
        data.push({
          date: new Date(element.date),
          high: element.adHigh,
          low: element.adLow,
          close: element.adClose,
          open: element.adOpen,
          volume: element.nmVolume,
          pctChange: element.pctChange,
          change: element.adChange,
          rsLine: rsLineData ? rsLineData.value : undefined,
          rsRating: rsRatingData ? rsRatingData.value : undefined,
        });
      });
      this.dataGraph = {
        name: `${dataR[3].code} - ${dataR[3].name}`,
        data,
      };
    }
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default CorrelationGraphChartStore;

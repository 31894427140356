import { observable, action, makeAutoObservable } from 'mobx';
import NotificationStore, { NotificationProps } from './notificationStore';
import api from './request';

export interface NewActionProps {
  dataAction: ActionData;
  dataNews: Array<NewData>;
  newData?: NewData;
  categories: Array<CategoryData>;
  categoryID: number;
  setCategoryID: (categoryID: number) => void;
  loadDataAction: () => void;
  loadDataNew: () => void;
  loadDetailsNew: (id: number) => void;
  setTabActive: (tabActive: string) => void;
  updateDataAction: (data: ActionData) => void;
  tabs: Array<string>;
  tabActive: string;
  loadingUpdate: boolean;
  loading: boolean;
  loadingDetails: boolean;
}

export interface ActionData {
  tongQuan: string;
  hanhDong: string;
  phanTich: string;
}

export interface NewData {
  id: number;
  image?: string;
  title: string;
  meta: string;
  content?: string;
  latest: Array<{ id: number; image: string; meta: string; title: string }>;
  relate: Array<{ id: number; image: string; meta: string; title: string }>;
}

export interface CategoryData {
  id: number;
  name: string;
}

class NewActionStore implements NewActionProps {
  private noti: NotificationProps = NotificationStore;
  private apiAction: string = 'api/note';
  private apiNew: string = 'api/news';

  @observable
  dataAction: ActionData = { tongQuan: '', phanTich: '', hanhDong: '' };

  @observable
  dataNews: Array<NewData> = [];

  @observable
  newData?: NewData;

  @observable
  loadingUpdate: boolean = false;

  @observable
  loading: boolean = false;

  @observable
  loadingDetails: boolean = false;

  @observable
  categoryID: number = 1;

  @observable
  tabActive: string = 'Tin tức';

  tabs: Array<string> = ['Tin tức'];

  categories: Array<CategoryData> = [
    { id: 1, name: 'Tất cả' },
    { id: 2, name: 'Thị trường chứng khoán' },
    { id: 3, name: 'Tài chính' },
    { id: 5, name: 'Kinh tế vĩ mô - Đầu tư' },
    { id: 6, name: 'Doanh nghiệp' },
    { id: 7, name: 'Bất động sản' },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setTabActive = (tabActive: string) => {
    if (tabActive !== this.tabActive) {
      this.tabActive = tabActive;
      this.tabActive === 'Tin tức' && this.loadDataAction();
    }
  };

  @action
  public setCategoryID = (categoryID: number) => {
    if (categoryID !== this.categoryID) {
      this.categoryID = categoryID;
      this.loadDataNew();
    }
  };

  @action
  public loadDetailsNew = (id: number) => {
    this.loadingDetails = true;
    const queryString: string = `/${this.apiNew}/${id}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDetailsNewSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDetailsNewSuccess = (data: NewData) => {
    this.newData = data;
    this.loadingDetails = false;
  };

  @action
  public loadDataNew = () => {
    this.loading = true;
    const queryString: string = `/${this.apiNew}?s=${this.categoryID}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataNewSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataNewSuccess = (data: Array<NewData>) => {
    this.dataNews = data;
    this.loading = false;
  };

  @action
  public updateDataAction = (data: ActionData) => {
    this.loadingUpdate = true;
    const queryString: string = `/${this.apiAction}`;
    api
      .post(queryString, data)
      .then(() => this.updateDataActionSuccess(data))
      .catch(this.updateDataError);
  };

  @action
  private updateDataActionSuccess = (data: ActionData) => {
    this.dataAction = data;
    this.loadingUpdate = false;
    this.noti.showSuccessMessage('Success');
  };

  @action
  public loadDataAction = () => {
    this.loading = true;
    const queryString: string = `/${this.apiAction}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataActionSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataActionSuccess = (data: ActionData) => {
    this.dataAction = data;
    this.loading = false;
  };

  @action
  private updateDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loadingUpdate = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
    this.loadingDetails = false;
    this.noti.showErrorMessage(error.message);
  };
}

export default new NewActionStore();

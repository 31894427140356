import { observable, action, makeAutoObservable } from 'mobx';
import api from '../requestWithAuth';
import { CodeWatchListProps } from '../RRGWatchListStore';

export interface CG0LeaderBoardStoreProps {
  data: Array<StockLeader>;
  loadData: () => void;
  loading: boolean;
  selectedLeaderStock: StockLeader;
  setSelectedLeaderStock: (selectedStock: any) => void;
  codeToWatchListAction: (codeWatchListProps: any) => void;
  isShowLeaderBoardChart: boolean;
  setShowLeaderBoardChart: (action: boolean) => void;
  selectedLeaderStockChart: StockLeader;
  setSelectedLeaderStockChart: (selectedStock: any) => void;
  // selectedMarket: GCMarketData;

  // updateData: (data: any) => void;
}

export interface News {
  id: number;
  image: string;
  title: string;
  meta: string;
}

export interface LeaderItem {
  id: number;
  leaderboard_id: number;
  code: string;
  date: string;
  est_profit: number | null;
  est_loss: number | null;
  profit: number | null;
  target_price: number | null;
  recommend: number | null;
  range_buy: string | null;
  stoploss: number | null;
  range_sell: string | null;
  strategy: string;
  avg_buy: number | null;
  avg_sell: number | null;
  updated_at: string;
  adClose:number;
}

export interface StockLeader {
  follow: number;
  id: number;
  code: string;
  backstory: string;
  action: string;
  catalyst: string;
  plan: string;
  updated_at: string;
  shortName: string;
  sectorId: number;
  adClose: number;
  pctChange: number;
  rsRating: string;
  smrRating: string | null;
  epsRating: string | null;
  pctVolMa20: number;
  sectorRating: string;
  leaderList: LeaderItem[];
  isFollow: boolean;
  isClose: boolean;
  profit: number;
  news: News[];
  recommend: number | null;
  est_profit: number | null;
  est_loss: number | null;
  target_price: number | null;
  range_buy: string | null;
  stoploss: number | null;
  range_sell: string | null;
  strategy: string | null;
  avg_buy: number | null;
  avg_sell: number | null;
  ceilingPrice: number;
  floorPrice: number;
}

class CG0LeaderBoardStore implements CG0LeaderBoardStoreProps {
  private apiPath: string = 'api/leaderboard';
  private apiFollowPath: string = 'api/watchlist';

  @observable
  data: Array<StockLeader> = [];

  @observable
  loading: boolean = true;

  @observable
  isShowLeaderBoardChart: boolean = false;

  @observable
  selectedLeaderStockChart: StockLeader = {
    code: '',
    backstory: '',
    action: '',
    catalyst: '',
    plan: '',
    updated_at: '',
    shortName: '',
    sectorId: 0,
    adClose: 0,
    pctChange: 0,
    rsRating: '',
    smrRating: null,
    epsRating: null,
    pctVolMa20: 0,
    sectorRating: '',
    leaderList: [],
    isFollow: false,
    isClose: false,
    profit: 0,
    id: 0,
    news: [],
    recommend: null,
    est_profit: null,
    est_loss: null,
    target_price: null,
    range_buy: null,
    stoploss: null,
    range_sell: null,
    strategy: null,
    avg_buy: null,
    avg_sell: null,
    ceilingPrice: 0,
    floorPrice: 0,
    follow: 0
  };

  @observable
  selectedLeaderStock: StockLeader = {
    code: '',
    backstory: '',
    action: '',
    catalyst: '',
    plan: '',
    updated_at: '',
    shortName: '',
    sectorId: 0,
    adClose: 0,
    pctChange: 0,
    rsRating: '',
    smrRating: null,
    epsRating: null,
    pctVolMa20: 0,
    sectorRating: '',
    leaderList: [],
    isFollow: false,
    isClose: false,
    profit: 0,
    id: 0,
    news: [],
    recommend: null,
    est_profit: null,
    est_loss: null,
    target_price: null,
    range_buy: null,
    stoploss: null,
    range_sell: null,
    strategy: null,
    avg_buy: null,
    avg_sell: null,
    ceilingPrice: 0,
    floorPrice: 0,
    follow: 0
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public codeToWatchListAction = (props: CodeWatchListProps) => {
    const { action, watchlistID, stockCode } = props;
    if (action === 'CREATE') {
      const _code = { id: watchlistID, code: stockCode };
      this.loading = true;
      const queryString: string = `/${this.apiFollowPath}`;
      api
        .post(queryString, _code)
        .then((resp) => resp.data)
        .then(this.fetchDataSuccess)
        .then(() =>
          this.loadData(),
        )
        .catch(this.fetchDataError);
    } else if (action === 'DELETE') {

    }
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.selectedLeaderStock = this.data[0];
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public setSelectedLeaderStock = (selectedStock: any) => {
    this.selectedLeaderStock = selectedStock;
  };

  @action
  public setSelectedLeaderStockChart = (selectedStock: any) => {
    this.selectedLeaderStockChart = selectedStock;
  };

  @action
  public setShowLeaderBoardChart = (action: boolean) => {
    this.isShowLeaderBoardChart = action;
  };

}

export default new CG0LeaderBoardStore();

import moment from 'moment';
import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';
import WSClient, { WSClientProps } from './wsClient';
import { Global2kData } from './globalStore';
import { ISODateFormat } from '../utils/DateFormat';
import randomColor from '../const/colors';

export interface IndustryDevelopStoreProps {
  loadData: (sectorId?: string, isLoading?: boolean) => void;
  loading: boolean;
  data: Array<IData>;
  sectorID?: string;
  timePeroid?: '1M' | '3M' | '6M' | '1Y' | '2Y';
  startDate?: string;
  endDate?: string;
  updateStartDate: (updateStartDate?: string) => void;
  updateEndDate: (endDate?: string) => void;
  updateSectorID: (sectorID?: string) => void;
  updateChecked: (sectorID?: string, code?: string) => void;
  updateTimePeroid: (timePeroid: '1M' | '3M' | '6M' | '1Y' | '2Y') => void;
  startWebSocket: () => void;
  endWebSocket: () => void;
  clearSelected: () => void;
}

export interface IData {
  statusPctChange: number;
  statusAdClose: any;
  MarketCapital: number;
  adClose: number;
  name: string;
  code: string;
  nmValue: number;
  nmVolume: number;
  order: number;
  pctChange: number;
  rsRating: number;
  sectorId: string;
  checked: boolean;
  color: string;
  chart: Array<{ date: string; value: number }>;
}

class IndustryDevelopStoreStore implements IndustryDevelopStoreProps {
  private echoChannel: string = 'AllStockData';
  private wsClient: WSClientProps = WSClient;
  private apiPath: string = 'api/developments';

  private dataList: Array<Global2kData> = [];

  @observable
  data: Array<IData> = [];

  @observable
  loading: boolean = false;

  @observable
  sectorID?: string;

  @observable
  startDate?: string;

  @observable
  endDate?: string;

  @observable
  timePeroid?: '1M' | '3M' | '6M' | '1Y' | '2Y' = '6M';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.dataList = [];
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  private fetchDataSocketSuccess = (data: {
    end: 0 | 1;
    data: Array<Global2kData>;
  }) => {
    if (!data.end) {
      this.dataList = [...this.dataList, ...data.data];
    }
    if (data.end) {
      const dataTmp: Array<Global2kData> = [...this.dataList, ...data.data];
      this.updateSocketData(dataTmp);
      this.dataList = [];
    }
  };

  @action
  clearSelected = () => {
    this.data = this.data.map((k, index) => ({ ...k, checked: false }));
  };

  @action
  private updateSocketData(data: Array<Global2kData>) {
    if (data.length > 0 && this.data.length > 0) {
      const list = this.data.slice();
      const listChange = list.map((element, index) => {
        let item = element;
        const itemChange = data.find(
          (t) => t.code === element.sectorId || t.code === element.code,
        );
        if (itemChange) {
          if (
            element.chart[element.chart.length - 1].date ===
            moment().format(ISODateFormat)
          ) {
            let chart = element.chart.slice();
            chart[chart.length - 1].value = parseFloat(itemChange.pctChange);
            item.chart = chart;
          }
          item.adClose = parseFloat(itemChange.adClose);
          item.pctChange = parseFloat(itemChange.pctChange);
          item.statusAdClose =
            parseFloat(itemChange.adClose) > parseFloat(item.adClose.toString())
              ? 1
              : parseFloat(itemChange.adClose) <
                parseFloat(item.adClose.toString())
              ? 2
              : 0;
          item.statusPctChange =
            parseFloat(itemChange.pctChange) >
            parseFloat(item.pctChange.toString())
              ? 1
              : parseFloat(itemChange.pctChange) <
                parseFloat(item.pctChange.toString())
              ? 2
              : 0;
        }
        // item.color = randomColor(index, item.name || item.code)
        return item;
      });

      this.data = listChange;
    }
  }

  @action
  updateSectorID = (sectorID?: string) => (this.sectorID = sectorID);

  @action
  updateStartDate = (startDate?: string) => {
    this.startDate = startDate;
    if (
      this.endDate &&
      moment(startDate).isSameOrBefore(moment(this.endDate))
    ) {
      this.timePeroid = undefined;
      this.loadData(this.sectorID);
    }
  };

  @action
  updateEndDate = (endDate?: string) => {
    this.endDate = endDate;
    if (
      this.startDate &&
      moment(this.startDate).isSameOrBefore(moment(endDate))
    ) {
      this.timePeroid = undefined;
      this.loadData(this.sectorID);
    }
  };

  @action
  updateChecked = (sectorID?: string, code?: string) => {
    if (sectorID && !code) {
      this.data = this.data.slice().map((k, index) => ({
        ...k,
        checked: k.sectorId === sectorID ? !k.checked : k.checked,
        color: randomColor(index, k.name || k.code),
      }));
    } else if (!sectorID && code) {
      this.data = this.data.slice().map((k, index) => ({
        ...k,
        checked: k.code === code ? !k.checked : k.checked,
        color: randomColor(index, k.name || k.code),
      }));
    }
  };

  @action
  public updateTimePeroid = (timePeroid?: '1M' | '3M' | '6M' | '1Y' | '2Y') => {
    this.timePeroid = timePeroid;
    this.startDate = undefined;
    this.endDate = undefined;
    this.loadData(this.sectorID);
  };

  @action
  public loadData = (sectorID?: string, isLoading: boolean = true) => {
    this.loading = isLoading;
    let queryString: string = `${this.apiPath}`;
    if (sectorID) {
      queryString = `${this.apiPath}?sectorId=${sectorID}`;
      if (this.startDate && this.endDate) {
        queryString =
          queryString + `&from=${this.startDate}&to=${this.endDate}`;
      } else {
        let limit = 3;
        if (this.timePeroid === '3M') limit = 2;
        if (this.timePeroid === '1M') limit = 1;
        if (this.timePeroid === '1Y') limit = 4;
        if (this.timePeroid === '2Y') limit = 5;
        queryString = queryString + `&limit=${limit}`;
      }
    } else {
      queryString = `${this.apiPath}`;
      if (this.startDate && this.endDate) {
        queryString =
          queryString + `?from=${this.startDate}&to=${this.endDate}`;
      } else {
        let limit = 3;
        if (this.timePeroid === '3M') limit = 2;
        if (this.timePeroid === '1M') limit = 1;
        if (this.timePeroid === '1Y') limit = 4;
        if (this.timePeroid === '2Y') limit = 5;
        queryString = queryString + `?limit=${limit}`;
      }
    }
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then((res) => this.fetchDataSuccess(res, sectorID))
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: any, sectorID?: string) => {
    this.loading = false;
    const dataSort = data.sort(
      (a: any, b: any) => b.MarketCapital - a.MarketCapital,
    );
    if (sectorID) {
      const dataPares = dataSort.map((k: any, index: number) => ({
        ...k,
        sectorId: k.sectorId?.toString(),
        color: randomColor(index, k.code),
        chart: k.chart.map((c: any) => ({
          date: `${c.date.slice(0, 4)}-${c.date.slice(4, 6)}-${c.date.slice(
            6,
            8,
          )}`,
          value: parseFloat(c.value),
        })),
      }));
      const list = dataPares.map((k: any, i: number) => ({
        ...k,
        name: k.sectorId ? k.name : k.code,
      }));
      if (this.data.length > 0) {
        const arrayIsChecked = this.data.slice().filter((k) => k.checked);
        if (arrayIsChecked.length > 0) {
          let dataNew = list;
          for (let index = 0; index < dataNew.length; index++) {
            const element = dataNew[index];
            const findElement = arrayIsChecked.find(
              (k) => k.name === element.name,
            );
            if (findElement) {
              dataNew[index].checked = true;
            }
          }
          this.data = dataNew;
        } else {
          this.data = dataPares.map((k: any, i: number) => ({
            ...k,
            name: k.sectorId ? k.name : k.code,
            checked: i <= 4 || k.sectorId,
          }));
        }
      } else {
        this.data = dataPares.map((k: any, i: number) => ({
          ...k,
          name: k.sectorId ? k.name : k.code,
          checked: i <= 4 || k.sectorId,
        }));
      }
    } else {
      const list = dataSort.map((k: any, i: number) => ({
        ...k,
        sectorId: k.sectorId.toString(),
        color: randomColor(i, k.name || k.code),
        chart: k.chart.map((c: any) => ({
          date: `${c.date.slice(0, 4)}-${c.date.slice(4, 6)}-${c.date.slice(
            6,
            8,
          )}`,
          value: parseFloat(c.value),
        })),
      }));
      if (this.data.length > 0) {
        const arrayIsChecked = this.data.slice().filter((k) => k.checked);
        if (arrayIsChecked.length > 0) {
          let dataNew = list;
          for (let index = 0; index < dataNew.length; index++) {
            const element = dataNew[index];
            const findElement = arrayIsChecked.find(
              (k) => k.name === element.name,
            );
            if (findElement) dataNew[index].checked = true;
          }
          this.data = dataNew;
        } else {
          this.data = dataSort.map((k: any, i: number) => ({
            ...k,
            sectorId: k.sectorId.toString(),
            color: randomColor(i, k.name || k.code),
            chart: k.chart.map((c: any) => ({
              date: `${c.date.slice(0, 4)}-${c.date.slice(4, 6)}-${c.date.slice(
                6,
                8,
              )}`,
              value: parseFloat(c.value),
            })),
            checked: i <= 3,
          }));
        }
      } else {
        this.data = dataSort.map((k: any, i: number) => ({
          ...k,
          sectorId: k.sectorId.toString(),
          color: randomColor(i, k.name || k.code),
          chart: k.chart.map((c: any) => ({
            date: `${c.date.slice(0, 4)}-${c.date.slice(4, 6)}-${c.date.slice(
              6,
              8,
            )}`,
            value: parseFloat(c.value),
          })),
          checked: i <= 3,
        }));
      }
    }
    // console.log(this.data);
  };

  @action
  private fetchDataError = (error: any) => {
    this.loading = false;
  };
}

export default new IndustryDevelopStoreStore();

import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';
import moment, { Moment } from 'moment';
import { ISODateFormat } from '../../utils/DateFormat';

export interface EventStoreProps {
  data: {
    info: {
      code: string;
      name: string;
    };
    data: Array<EventData>;
  };
  types: Array<string>;
  code: string;
  typeIndex: number;
  loadData: () => void;
  loadDataTypes: () => void;
  setCode: (code: string, loadData?: boolean) => void;
  setTypeIndex: (typeIndex: number) => void;
  setFrom: (from: Moment) => void;
  setTo: (to: Moment) => void;
  loading: boolean;
  from?: Moment;
  to?: Moment;
}

export interface EventData {
  code: string;
  date: string;
  desc: string;
  id: string;
  note: string;
  type: string;
}

class EventStore implements EventStoreProps {
  private apiPath: string = 'api/company/event';
  private apiPathType: string = 'api/company/event/type';

  @observable
  loading: boolean = true;

  @observable
  data: {
    info: {
      code: string;
      name: string;
    };
    data: Array<EventData>;
  } = {
    info: { code: '', name: '' },
    data: [],
  };

  @observable
  types: Array<string> = [];

  @observable
  code: string = 'MBS';

  @observable
  typeIndex: number = 0;

  @observable
  from?: Moment;

  @observable
  to?: Moment;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setTypeIndex = (typeIndex: number) => {
    this.typeIndex = typeIndex;
    this.loadData();
  };

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadData();
  };

  @action
  public setFrom = (from: Moment) => {
    this.from = from;
    this.loadData();
  };

  @action
  public setTo = (to: Moment) => {
    this.to = to;
    this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    let type;
    if (this.typeIndex && this.types.length > 0) {
      const findType = this.types[this.typeIndex - 1];
      type = findType;
    }
    const fromDate = this.from ? this.from : moment().subtract(24, 'months');
    const toDate = this.to ? this.to : moment().add(1, 'months');

    const queryString: string = `${this.apiPath}?code=${this.code}${
      type ? '&type=' + type : ''
    }&from=${fromDate.format(ISODateFormat)}&to=${toDate.format(
      ISODateFormat,
    )}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadDataTypes = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPathType}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataTypesSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataTypesSuccess = (dataR: any) => {
    this.types = dataR;
    this.loading = false;
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default EventStore;

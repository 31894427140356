import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';

export interface NewsStoreProps {
  data?: NewsStoreData;
  code: string;
  loadData: () => void;
  setCode: (code: string, loadData?: boolean) => void;
  loading: boolean;
  setRelated: (id: number) => void;
}

export interface NewsStoreData {
  image: string;
  id: string;
  title: string;
  content: string;
  meta: string;
  relate: NewsItem[];
}

export interface NewsItem {
  id: number;
  image?: string;
  title: string;
  meta: string;
}

class NewsStore implements NewsStoreProps {
  private apiPath: string = 'api/company/news';

  @observable
  loading: boolean = true;

  @observable
  data?: NewsStoreData;

  @observable
  code: string = 'MBS';

  @observable
  relateId: number = -1;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    loadData && this.loadData();
  };

  @action
  public setRelated = (id: number) => {
    this.relateId = id;
    this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}?code=${this.code}&related=${this.relateId}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default NewsStore;

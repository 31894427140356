import { observable, action, makeAutoObservable } from 'mobx';
import { MarketChartZoomProps } from './marketChartZoomStore';
import api from './request';
import WSClient, { WSClientProps } from './wsClient';
import MarketZoomStore from './marketChartZoomStore';

export interface MarketIndexProps {
  data: Array<MarketIndexResponse>;
  marketTypes: Array<MarketType>;
  marketType: MarketType;
  storeMarketZoom: MarketChartZoomProps;
  setMarketType: (marketType: MarketType) => void;
  loadData: (callBack?: any) => void;
  startWebSocket: () => void;
  endWebSocket: () => void;
}

export interface MarketIndexResponse {
  id: number;
  code: string;
  floor: string;
  date: string;
  value: number;
  change: number;
  pctChange: number;
  status: 0 | 1 | 2;
  statusR: number;
  type: number;
  tz: string;
}

export interface MarketType {
  id: number;
  name: string;
}

class MarketIndexStore implements MarketIndexProps {
  private apiPath: string = 'api/market';

  private echoChannel: string = 'Market';

  private wsClient: WSClientProps = WSClient;

  storeMarketZoom: MarketChartZoomProps = MarketZoomStore;

  @observable
  data: Array<MarketIndexResponse> = [];

  dataBK: Array<MarketIndexResponse> = [];

  marketTypes: Array<MarketType> = [
    { id: 1, name: 'Chỉ số' },
    { id: 5, name: 'Hàng hoá' },
    { id: 6, name: 'Forex' },
  ];

  @observable
  marketType: MarketType = { id: 1, name: 'Chỉ số' };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setMarketType = (marketType: MarketType) =>
    (this.marketType = marketType);

  @action
  public loadData = async (callBack?: any) => {
    const queryString: string = `/${this.apiPath}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then((r) => this.fetchDataSuccess(r, callBack))
      .catch(this.fetchDataError);
  };

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  private fetchDataSuccess = (
    data: Array<MarketIndexResponse>,
    callBack?: any,
  ) => {
    const tmp = data.slice();
    const proccesData = tmp.map((t, index) => {
      if (t.code === 'VNMID') {
        t.code = 'MidCap';
        t.floor = 'Vốn hoá vừa';
      }
      if (t.code === 'VNSML') {
        t.code = 'SmallCap';
        t.floor = 'Vốn hoá nhỏ';
      }

      let status = 0;
      if (this.dataBK.length === 0) status = 0;
      else if (this.dataBK.length <= index) status = 0;
      else {
        if (t.value > this.dataBK[index].value) status = 1;
        else if (t.value < this.dataBK[index].value) status = 2;
        else status = 0;
      }
      return { ...t, statusR: status };
    });
    this.data = proccesData;
    this.dataBK = data;
    callBack(proccesData);
  };

  @action
  private fetchDataSocketSuccess = (data: Array<MarketIndexResponse>) => {
    const proccesData = this.data.map((t) => {
      let status: any = 0;
      let newData = t;
      const socketData = data.find((k) => k.code === t.code);
      if (socketData) newData = socketData;
      if (t.value < newData.value) status = 1;
      else if (t.value > newData.value) status = 2;
      else status = 0;
      newData.statusR = status;
      newData.id = t.id;
      newData.floor = t.floor;
      return newData;
    });
    this.data = proccesData;
    const endNodeZoom = this.data.find(
      (d) => d.code.toLowerCase() === 'vnindex',
    );
    endNodeZoom &&
      this.storeMarketZoom.setDataEndNodeZoom({
        code: endNodeZoom.code,
        value: endNodeZoom.value,
        change: endNodeZoom.change,
        pctChange: endNodeZoom.pctChange,
        date: endNodeZoom.date,
      });
    this.dataBK = data;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
  };
}

export default new MarketIndexStore();

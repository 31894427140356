import { observable, action, makeAutoObservable, runInAction } from 'mobx';
import api from './request';

export const TimePeroidTypes = ['1d', '1w'];

export const StockTypes = ['KLGD', 'GTGD'];

export interface KhoiNgoaiChartProps {
  data: Array<KhoiNgoaiData>;
  dataZoom: Array<KhoiNgoaiDataZoom>;
  dataTable: Array<KhoiNgoaiData>;
  loadData: (isLoading?: boolean) => void;
  setTimePeroid: (timePeroid: string) => void;
  setStockType: (stockType: string) => void;
  setDataCode: (dataCode: string) => void;
  setDataStockR: (dataStockR?: {
    adClose: number;
    adChange: number;
    pctChange: number;
    code: string;
    isIndex?: boolean;
  }) => void;
  dataCode: string;
  dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
    isIndex?: boolean;
  };
  timePeroid: string;
  stockType: string;
  loading: boolean;
  statusTime?: string;
  setOpenZoom: (open: boolean) => void;
  openZoom: boolean;
}

export interface KhoiNgoaiData {
  code: string;
  tradingDate: string;
  netVal: number;
  netVol: number;
  sellVal: number;
  sellVol: number;
  buyVal: number;
  buyVol: number;
}

export interface KhoiNgoaiDataZoom {
  date: string;
  adClose: number;
}

class KhoiNgoaiChartStore implements KhoiNgoaiChartProps {
  private apiPath: string = 'api/foreigns';
  private apiPathZoom: string = 'api/history?code=';

  @observable
  data: Array<KhoiNgoaiData> = [];

  @observable
  dataZoom: Array<KhoiNgoaiDataZoom> = [];

  @observable
  dataTable: Array<KhoiNgoaiData> = [];

  @observable
  timePeroid: string = '1d';

  @observable
  openZoom: boolean = false;

  @observable
  stockType: string = 'GTGD';

  @observable
  dataCode: string = 'vnindex';

  @observable
  dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
  };

  @observable
  loading: boolean = false;

  @observable
  statusTime?: string = '';

  @action
  public setOpenZoom = async (open: boolean) => {
    this.openZoom = open;
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setDataStockR = (dataStockR?: {
    code: string;
    adClose: number;
    adChange: number;
    pctChange: number;
  }) => {
    runInAction(() => {
      this.dataStockR = dataStockR;
    });
  };

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.timePeroid = timePeroid;
    this.loadData();
  };

  @action
  public setStockType = (stockType: string) => {
    this.stockType = stockType;
  };

  @action
  public setDataCode = (dataCode: string) => {
    this.dataCode = dataCode;
    this.dataStockR = undefined;
    this.loadData();
  };
  // @action
  // public loadData = async () => {
  // 	await this.loadData()
  // 	runInAction(() => {
  // 		const time = moment().format(StatusDateFormat);
  // 		this.statusTime = time.toString();
  // 	})
  // }

  @action
  public loadData = (isLoading: boolean = true) => {
    this.loading = isLoading;
    // const type = this.stockType === "KLGD" ? "Vol" : "Val"
    const queryString: string = `${
      this.apiPath
    }?code=${this.dataCode.toLowerCase()}&group=${this.timePeroid}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
    api
      .get(this.apiPathZoom + this.dataCode)
      .then((resp) => resp.data)
      .then((res) => {
        runInAction(() => {
          this.dataZoom = res;
        });
      })
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: {
    chart: Array<KhoiNgoaiData>;
    list: Array<KhoiNgoaiData>;
  }) => {
    this.data = data.chart;
    this.dataTable = data.list;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    this.loading = false;
  };
}

export default KhoiNgoaiChartStore;

import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface AppBlockProps {
  children?: React.ReactNode;
  title?: string;
  // background?: string;
  height?: number | string;
  width?: number;
  marginRight?: any;
  marginTop?: any;
  paddingLeft?: any;
}

export const AppBlock: React.FC<AppBlockProps> = ({
  children,
  title,
  height,
  // background,
  width,
  marginRight,
  marginTop,
  paddingLeft,
}) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        display: 'flex',
        background: theme.palette.background.paper,
        height: height ? height : 410,
        width: width ? width : '100%',
        marginRight: marginRight ? marginRight : undefined,
        paddingLeft: paddingLeft ? paddingLeft : undefined,
        marginTop: marginTop ? marginTop : undefined,
        backgroundImage: 'unset',
      }}
    >
      {title && (
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';
import { Moment } from 'moment';
import WSClient, { WSClientProps } from './wsClient';
import { Global2kData } from './globalStore';
import moment from 'moment';
import { ISODateFormat } from '../utils/DateFormat';

export const DatePeroidTypes = ['30D', '60D', '90D', '180D'];
export const StatusTypes = ['all', 'buy', 'sell'];

export interface TradingSignalStoreProps {
  data: TradingSignalData[];
  historyData: SignalHistoryData[];
  loading: boolean;
  historyLoading: boolean;
  datePeroid: string;
  filter: SignalHistoryFilter;
  updateHistoryFilter: () => void;
  updateHistoryFilterByDateRange: () => void;
  startWebSocket: () => void;
  endWebSocket: () => void;
  clearData: () => void;
}

export interface TradingSignalData {
  label: string;
  data: SignalData[];
}

export interface SignalData {
  code: string;
  adClose: number;
  pctChange: number;
  ratio: string;
  stoploss: number;
  rsRating: number;
  smrRating: number;
  epsRating: number;
  rsSector: string;
  strength: number;
  status: number;
}

export interface SignalHistoryData {
  code: string;
  buy_date: string;
  sell_date: string;
  profit_loss: number;
  buy_area: string;
  adClose: number;
}

export interface SignalHistoryFilter {
  code: string;
  status: string;
  from: Moment;
  to: Moment;
}

class TradingSignalStore implements TradingSignalStoreProps {
  private apiPath: string = 'api/signal';
  private apiHistoryPath: string = 'api/signal/history';
  private echoChannel: string = 'AllStockData';
  private wsClient: WSClientProps = WSClient;
  private dataList: Array<Global2kData> = [];

  @observable
  loading: boolean = true;

  @observable
  datePeroid: string = '30D';

  @observable
  chartSelectedType: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  @observable
  historyLoading: boolean = false;

  @action
  updateHistoryFilter = async () => {
    this.loadHistoryData();
  };

  @action
  updateHistoryFilterByDateRange = async () => {
    this.loadHistoryData();
  };

  @observable
  filter: SignalHistoryFilter = {
    code: 'all',
    status: 'all',
    from: moment().subtract(1, 'months'),
    to: moment(),
  };

  @observable
  historyData: SignalHistoryData[] = [];

  @observable
  data: TradingSignalData[] = [
    { label: 'Tín hiệu Mua', data: [] },
    { label: 'Tín hiệu Bán', data: [] },
    { label: 'Tín hiệu Canh Mua', data: [] },
    { label: 'Tín hiệu Canh Bán', data: [] },
  ];

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public clearData = async () => {
    this.loading = true;
    this.data = [
      { label: 'Tín hiệu Mua', data: [] },
      { label: 'Tín hiệu Bán', data: [] },
      { label: 'Tín hiệu Canh Mua', data: [] },
      { label: 'Tín hiệu Canh Bán', data: [] },
    ];
  };

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.dataList = [];
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  private fetchDataSocketSuccess = (data: {
    end: 0 | 1;
    data: Array<Global2kData>;
  }) => {
    if (!data.end) {
      this.dataList = [...this.dataList, ...data.data];
    }
    if (data.end) {
      // const dataTmp: Array<Global2kData> = [...this.dataList, ...data.data];
      this.dataList = [];
    }
    this.updateTradingSignalData(data.data);
  };

  @action
  private updateTradingSignalData = (dataSocket: Array<Global2kData>) => {
    if (dataSocket.length > 0) {
      dataSocket.map((t) => {
        this.data.map((i) => {
          i.data.map((j) => {
            if (t.code.toString().toLowerCase() === j.code.toLowerCase()) {
              j.adClose = Number(t.adClose);
              j.pctChange = Number(t.pctChange);
            }
          });
        });
      });
    }
  };

  @action
  public loadHistoryData = async () => {
    this.historyLoading = true;
    const queryString: string = `${this.apiHistoryPath}?codes=${this.filter.code}&from=${this.filter.from.format(ISODateFormat)}&to=${this.filter.to.format(ISODateFormat)}&status=${this.filter.status}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchHistoryDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchHistoryDataSuccess = (dataR: any) => {
    this.historyData = dataR;
    this.historyLoading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    console.error(error);
    this.loading = false;
  };

  @action
  public setDatePeroid = (datePeroid: string) => {
    this.datePeroid = datePeroid;
    this.loadData();
  };
}

export default new TradingSignalStore();

const IndustryVolatilityIcon = (props: any) => {
  const { active } = props;
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2660_5490)">
        <path
          d="M5 11C5 10.4477 5.44772 10 6 10H25C25.5523 10 26 10.4477 26 11V11C26 11.5523 25.5523 12 25 12H6C5.44772 12 5 11.5523 5 11V11Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M5 6C5 5.44772 5.44772 5 6 5H19C19.5523 5 20 5.44772 20 6V6C20 6.55228 19.5523 7 19 7H6C5.44772 7 5 6.55228 5 6V6Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M5 16C5 15.4477 5.44772 15 6 15H9C9.55228 15 10 15.4477 10 16V16C10 16.5523 9.55228 17 9 17H6C5.44772 17 5 16.5523 5 16V16Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M5 21C5 20.4477 5.44772 20 6 20H15C15.5523 20 16 20.4477 16 21V21C16 21.5523 15.5523 22 15 22H6C5.44771 22 5 21.5523 5 21V21Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
        <path
          d="M1.26562 26.4062H25.7344C25.9126 26.4062 26.0835 26.3355 26.2095 26.2095C26.3355 26.0835 26.4062 25.9126 26.4062 25.7344C26.4062 25.5562 26.3355 25.3853 26.2095 25.2593C26.0835 25.1333 25.9126 25.0625 25.7344 25.0625H1.9375V1.26562C1.9375 1.08743 1.86671 0.916539 1.74071 0.790538C1.61471 0.664537 1.44382 0.59375 1.26562 0.59375C1.08743 0.59375 0.916539 0.664537 0.790538 0.790538C0.664537 0.916539 0.59375 1.08743 0.59375 1.26562V25.7344C0.59375 25.9126 0.664536 26.0835 0.790538 26.2095C0.916539 26.3355 1.08743 26.4062 1.26562 26.4062Z"
          fill={active ? '#F2BB0E' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2660_5490">
          <rect width={27} height={27} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IndustryVolatilityIcon;

import { covertNumber } from '../utils/NumberFormat';

export const getColorsRRG = (str: string): string => {
  str = str + 'alpha_stock';
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 3) - hash);
  }
  var color = Math.abs(hash).toString(16).substring(0, 6);

  return '#' + color;
};
export const toSlug = (str: string) => {
  // Chuyển hết sang chữ thường
  str = str.toLowerCase();
  // xóa dấu
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  str = str.replace(/(đ)/g, 'd');
  // Xóa ký tự đặc biệt
  str = str.replace(/([^0-9a-z-\s])/g, '');
  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, '-');
  // xóa phần dự - ở đầu
  str = str.replace(/^-+/g, '');
  // xóa phần dư - ở cuối
  str = str.replace(/-+$/g, '');
  // return
  return str;
};

export const getColorRealtime = (
  type: 'cp' | 'index' | 'rating',
  value: any,
): string => {
  switch (type) {
    case 'rating':
      if (value === 'A') return '#980dae';
      if (value === 'B') return '#109e1a';
      if (value === 'C') return '#ab8614';
      if (value === 'D') return '#ff0000';
      if (value === 'E') return '#1499B6';
      if (value >= 90) return '#980DAE';
      if (value >= 80 && value < 90) return '#0D9E1B';
      if (value >= 60 && value < 80) return '#AB8613';
      return '#D31818';
    case 'cp':
      if (covertNumber(value.adClose) === covertNumber(value.ceilingPrice))
        return '#ff25ff';
      if (covertNumber(value.adClose) === covertNumber(value.floorPrice))
        return '#1eeeee';
      if (value.pctChange > 0) return '#0f0';
      if (covertNumber(value.pctChange) === covertNumber(0)) return '#ffd900';
      return '#ff3737';
    case 'index':
      if (value.pctChange > 0) return '#0f0';
      if (value.pctChange === 0) return '#ffd900';
      return '#ff3737';
    default:
      return '';
  }
};

export const getOffsetColor = (value: any): string => {
  if (value === null) return '#41495C';
  if (value >= 0) return '#0D9E1B';
  else return '#FF0000';
};

export const codeFormat = (str: string) => {
  // Chuyển hết sang chữ in thường
  str = str.toLowerCase();
  // xóa dấu
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  str = str.replace(/(đ)/g, 'd');

  // eslint-disable-next-line
  str = str.replace(/(\-+)/g, '');
  // Xóa ký tự đặc biệt
  // eslint-disable-next-line
  str = str.replace(/([^0-9a-z-\s])/g, '');
  // xóa phần dự - ở đầu
  str = str.replace(/^-+/g, '');
  // xóa phần dư - ở cuối
  str = str.replace(/-+$/g, '');
  return str.toUpperCase();
};

export const getCharacter = (name?: string) => {
  if (name === undefined || name === null || name === '') return 'A';
  var names = name.split(' ');
  return Array.from(names[names.length - 1])[0];
};

export const codeShortNameFormat = (str: string) => {
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
  str = str.replace(/(đ)/g, 'd');
  str = str.toUpperCase();
  var matches = str.match(/\b(\w)/g);
  return matches ? matches.join('') : str;
};

export const currencyFormat = (value: any): string => {
  return value.toLocaleString('vi-VN', {
    style: 'currency',
    currency: 'vnd',
    minimumFractionDigits: 0,
  });
};

import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export interface TopCoPhieuProps {
  dataIncrease: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  };
  dataBreakthrough: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  };
  loadData: () => void;
  updateDataIncrease: (dataIncrease: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  }) => void;
  updateDataBreakthrough: (dataBreakthrough: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  }) => void;
  loading: boolean;
  statusTime?: string;
}

export interface TopCoPhieuData {
  code: string;
  floor: string;
  adClose: number;
  pctChange: number;
  nmVolume: number;
  volMa20: number;
  statusAdClose?: 0 | 1 | 2;
  statusPctChange?: 0 | 1 | 2;
  statusVolMa20?: 0 | 1 | 2;
  statusNmVolume?: 0 | 1 | 2;
}

class TopCoPhieuStore implements TopCoPhieuProps {
  private apiPath: string = 'api/stock/top';

  @observable
  dataIncrease: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  } = {
    HOSE: [],
    VN30: [],
    HNX: [],
    HNX30: [],
    UPCOM: [],
  };

  @observable
  dataBreakthrough: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  } = {
    HOSE: [],
    VN30: [],
    HNX: [],
    HNX30: [],
    UPCOM: [],
  };

  @observable
  loading: boolean = false;

  @observable
  statusTime?: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  updateDataIncrease = (dataIncrease: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  }) => {
    this.dataIncrease = dataIncrease;
  };

  @action
  updateDataBreakthrough = (dataBreakthrough: {
    HOSE: Array<TopCoPhieuData>;
    VN30: Array<TopCoPhieuData>;
    HNX: Array<TopCoPhieuData>;
    HNX30: Array<TopCoPhieuData>;
    UPCOM: Array<TopCoPhieuData>;
  }) => {
    this.dataBreakthrough = dataBreakthrough;
  };

  @action
  public loadData = () => {
    const queryString: string = `${this.apiPath}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (data: any) => {
    this.dataIncrease = data.increase;
    this.dataBreakthrough = data.breakthrough;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    this.loading = false;
  };
}

export default new TopCoPhieuStore();

import axios from 'axios';
import { AppBaseUrl as baseURL } from '../const/config';

function buildApi() {
  const instance = axios.create({
    baseURL,
    withCredentials: false,
  });

  instance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      ...config.headers,
    },
    withCredentials: false,
  }));

  instance.interceptors.response.use(
    (response) => response,
    // error => {
    //     // if (error.response && 401 === error.response.status) {
    //     //     console.log(error);
    //     // }

    //     return Promise.reject(error);
    // }
  );

  return instance;
}

const api = buildApi();
export default api;

import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

export interface IDNumberFormat {
  name: string;
  defaultValue?: number | string | undefined;
  onChange?: (name: string, value?: string) => void;
  presion?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  // type: 'number' | 'money';
  control: any;
  rules?: any;
}

const NumberInput: React.FC<any> = React.forwardRef((props, ref) => (
  <TextField
    {...props}
    sx={{ width: '100%', height: 35 }}
    inputProps={{ autoComplete: 'off' }}
    ref={ref}
  />
));

export const AppNumberFormat: React.FC<IDNumberFormat> = ({
  name,
  // type,
  presion,
  min,
  max,
  disabled,
  control,
  defaultValue,
  rules,
  onChange,
}) => {
  const decimalSeparator = ',';
  const decimalScale = presion ? presion : 0;
  const thousandSeparator = decimalSeparator === ',' ? '.' : ',';
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => {
        return (
          <NumberFormat
            customInput={NumberInput}
            decimalScale={decimalScale}
            min={min}
            max={max}
            value={field.value}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            onValueChange={(value: NumberFormatValues) => {
              if (onChange !== undefined) onChange(name, value.value);
              return field.onChange(value.value);
            }}
            disabled={!!disabled}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;
              if (floatValue) {
                if ((min && max) || (min === 0 && max))
                  return (
                    formattedValue === '' ||
                    (floatValue >= min && floatValue <= max)
                  );
                if (min || min === 0)
                  return formattedValue === '' || floatValue >= min;
                if (max) return formattedValue === '' || floatValue <= max;
              }
              return true;
            }}
            autoComplete="off"
            size="small"
          />
        );
      }}
    />
  );
};

import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';
import apiAuthen from '../requestWithAuth';
import { v4 as uuidv4 } from 'uuid';
import { tabTaiChinhsdefaultSECURITIES } from '../../const/SECURITIES';
import { tabTaiChinhsdefaultBANK } from '../../const/BANK';
import { tabTaiChinhsdefaultNON_FINANCE } from '../../const/NON_FINANCE';
import { tabTaiChinhsdefaultINSURANCE } from '../../const/INSURANCE';

export interface TabTaiChinhData {
  label: string;
  data: Array<{
    labelID: number;
    label: string;
    unit: string;
    data: Array<{ data: any }>;
    displayLevel?: number;
  }>;
}

export interface GridItem {
  i: string;
  w: number;
  h: number;
  x: number;
  y: number;
}

export interface ChartConfig {
  label?: string;
  type: 'line' | 'bar' | 'stackedbar';
  color: string;
  dataKey: number;
}
export interface Chart {
  id: string;
  isSelected?: boolean;
  peroidDefault?: number;
  type: 'default' | 'customize';
  labels: Array<string>;
  chartname: string;
  chartconfig: Array<ChartConfig>;
}
export interface TabTaiChinh {
  index: number;
  id: string;
  name: string;
  data: Array<Chart>;
  layout: Array<GridItem>;
}

export interface TabFinanceStoreProps {
  code: string;
  period: number;
  setCode: (code: string, loadData?: boolean) => void;
  setPeriod: (period: number) => void;
  data: {
    name: string;
    data: TabTaiChinhData[];
    companyForm: 'INSURANCE' | 'BANK' | 'SECURITIES' | 'NON_FINANCE';
  };
  dataCustomize: TabTaiChinhData[];
  loadDataCustomize: () => void;
  loadData: () => void;
  loading: boolean;
  loadingCustomize: boolean;
  openNewPageTaiChinh: boolean;
  openEditNameTaiChinh: boolean;
  setOpenEditNameTaiChinh: (openEditNameTaiChinh: boolean) => void;
  setOpenNewPageTaiChinh: (openNewPageTaiChinh: boolean) => void;
  removeTaiChinhChart: (id: string) => void;
  tabActive: TabTaiChinh;
  setTabActive: (tabIndex: number) => void;
  removeTaiChinhValue: () => void;
  tabTaiChinhs: Array<TabTaiChinh>;
  addTabTaiChinh: (pageName: string) => void;
  saveChartsInTab: (
    chartDefaultIDS: Array<string>,
    chartCusIDS: Array<string>,
    chartsRemove: Array<string>,
  ) => void;
  addChartCutomize: (data: Chart) => void;
  updateChartCustomize: (data: Chart) => void;
  updateTabTaiChinhName: (pageName: string) => void;
  defaultCharts: Array<Chart>;
  customizeCharts: Array<Chart>;
  onLayoutUpdate: (layout: Array<GridItem>) => void;
  resetTabMacDinh: () => void;
}

class TabFinanceStore implements TabFinanceStoreProps {
  private apiPath: string = 'api/company/finance_chart';
  private apiPathCustom: string = 'api/company/finance_chart/custom';

  @observable
  defaultCharts: Array<Chart> = [];

  @observable
  customizeCharts: Array<Chart> = [];

  @observable
  period: number = 9;

  @observable
  loading: boolean = true;

  @observable
  loadingCustomize: boolean = true;

  @observable
  code: string = 'MBS';

  @observable
  dataCustomize: TabTaiChinhData[] = [];

  @observable
  data: {
    name: string;
    data: TabTaiChinhData[];
    companyForm: 'INSURANCE' | 'BANK' | 'SECURITIES' | 'NON_FINANCE';
  } = { name: '', data: [], companyForm: 'SECURITIES' };

  @observable
  openNewPageTaiChinh: boolean = false;

  @observable
  openEditNameTaiChinh: boolean = false;

  @observable
  tabActive: TabTaiChinh = {
    index: 0,
    id: '1',
    name: 'Trang mặc định',
    data: [],
    layout: [],
  };

  @observable
  tabTaiChinhs: Array<TabTaiChinh> = [
    {
      index: 0,
      id: '1',
      name: 'Trang mặc định',
      data: [],
      layout: [],
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public onLayoutUpdate = (layout: Array<GridItem>) => {
    const tabs = this.tabTaiChinhs.slice().map((k) => {
      if (k.id === this.tabActive.id) {
        return { ...k, layout: layout };
      }
      return k;
    });
    this.tabTaiChinhs = tabs;
    const tab = tabs.find((k) => this.tabActive.id === k.id);
    console.log('onLayoutUpdate', tab);
    if (tab) this.tabActive = tab;
    this.saveTablist();
  };

  @action
  public loadDataCustomize = async () => {
    this.loadingCustomize = true;

    const queryString: string = `${this.apiPathCustom}?code=${this.code}&cache=true`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccessCustomize)
      .catch(this.fetchDataError);
  };

  @action
  public loadData = async () => {
    this.loading = true;

    const queryString: string = `${this.apiPath}?code=${this.code}&cache=true`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public addChartCutomize = (data: Chart) => {
    if (data.id) {
      const tabs = this.tabTaiChinhs.map((tab) => {
        if (tab.id === this.tabActive.id) {
          const tb = tab;
          tb.data = [...tb.data, data];
          tb.layout = this.generateArrayLayouts(tb.data);
          return tb;
        }
        return tab;
      });
      this.tabTaiChinhs = tabs;
      this.customizeCharts = [...this.customizeCharts, data].sort((a, b) =>
        ('' + a.chartname).localeCompare(b.chartname),
      );
      const tab = tabs.find((k) => this.tabActive.id === k.id);
      if (tab) this.tabActive = tab;
      this.saveTablist();
    }
  };

  @action
  public updateChartCustomize = (data: Chart) => {
    const tabs = this.tabTaiChinhs.map((tab) => {
      if (tab.id === this.tabActive.id) {
        const tb = tab;
        const dataChart = tb.data.find((t) => t.id === data.id);
        if (dataChart) {
          const charts = tb.data.map((c) => {
            if (c.id === data.id) {
              return data;
            }
            return c;
          });
          tb.data = charts;
        }
        return tb;
      }
      return tab;
    });
    const customizeCharts = this.customizeCharts.map((c) => {
      if (c.id === data.id) {
        return data;
      }
      return c;
    });
    this.tabTaiChinhs = tabs;
    this.customizeCharts = customizeCharts;
    const tab = tabs.find((k) => this.tabActive.id === k.id);
    if (tab) this.tabActive = tab;
    this.saveTablist();
  };

  @action
  public removeChartsInTab = (chartIDS: Array<string>) => {
    let tabs = this.tabTaiChinhs.slice();
    if (chartIDS.length > 0) {
      tabs = this.tabTaiChinhs.map((tab) => {
        if (tab.id === this.tabActive.id) {
          const tb = tab;
          chartIDS.forEach((chartID) => {
            const check = tab.data.find((k) => k.id === chartID);
            if (check) {
              tb.data = tb.data.filter((k) => k.id !== check.id);
              tb.layout = tb.layout.filter((k) => k.i !== check.id);
            }
          });

          return tb;
        }
        return tab;
      });
      this.tabTaiChinhs = tabs;
      const tab = tabs.find((k) => this.tabActive.id === k.id);
      if (tab) this.tabActive = tab;
      this.saveTablist();
    }
  };

  @action
  public saveChartsInTab = (
    chartDefaultIDS: Array<string>,
    chartCusIDS: Array<string>,
    chartsRemove: Array<string>,
  ) => {
    let tabs = this.tabTaiChinhs.slice();
    if (chartsRemove.length > 0) {
      tabs = tabs.map((tab) => {
        if (tab.id === this.tabActive.id) {
          const tb = tab;
          chartsRemove.forEach((chartID) => {
            const check = tab.data.find((k) => k.id === chartID);
            if (check) {
              tb.data = tb.data.filter((k) => k.id !== check.id);
              tb.layout = this.generateArrayLayouts(tab.data);
            }
          });

          return tb;
        }
        return tab;
      });
    }

    if (chartDefaultIDS.length > 0) {
      tabs = tabs.map((tab) => {
        if (tab.id === this.tabActive.id) {
          const tb = tab;
          chartDefaultIDS.forEach((chartID) => {
            const check = tab.data.find((k) => k.id === chartID);
            let tabTaiChinhsdefault: Array<Chart> = [];
            if (this.data.companyForm === 'SECURITIES')
              tabTaiChinhsdefault = tabTaiChinhsdefaultSECURITIES;
            if (this.data.companyForm === 'BANK')
              tabTaiChinhsdefault = tabTaiChinhsdefaultBANK;
            if (this.data.companyForm === 'NON_FINANCE')
              tabTaiChinhsdefault = tabTaiChinhsdefaultNON_FINANCE;
            if (this.data.companyForm === 'INSURANCE')
              tabTaiChinhsdefault = tabTaiChinhsdefaultINSURANCE;
            const chartData = tabTaiChinhsdefault.find((k) => k.id === chartID);
            if (chartData && chartData.type === 'default' && !check) {
              tb.data = [...tb.data, chartData];
              tb.layout = this.generateArrayLayouts(tab.data);
            }
          });

          return tb;
        }
        return tab;
      });
    }
    if (chartCusIDS.length > 0) {
      tabs = tabs.map((tab) => {
        if (tab.id === this.tabActive.id) {
          const tb = tab;
          chartCusIDS.forEach((chartID) => {
            const check = tab.data.find((k) => k.id === chartID);
            const chartDataCust = this.customizeCharts.find(
              (k) => k.id === chartID,
            );
            if (chartDataCust && !check && chartDataCust.type === 'customize') {
              tb.data = [...tb.data, chartDataCust];
              tb.layout = this.generateArrayLayouts(tab.data);
            }
          });

          return tb;
        }
        return tab;
      });
    }
    this.tabTaiChinhs = tabs;
    let tab = tabs.find((k) => this.tabActive.id === k.id);
    if (tab) {
      this.tabActive = tab;
    }
    this.saveTablist();
  };

  @action
  public setPeriod = (period: number) => {
    this.period = period;
  };

  @action
  public setCode = (code: string, loadData: boolean = true) => {
    this.code = code;
    if (loadData) {
      this.loadDataCustomize();
    }
  };

  @action
  public removeTaiChinhChart = (id: string) => {
    let tabs = this.tabTaiChinhs.map((k) => {
      if (k.id === this.tabActive.id) {
        return {
          ...k,
          data: k.data.filter((c) => c.id !== id),
          layout: k.layout.filter((k) => k.i !== id),
        };
      }
      return k;
    });
    this.tabTaiChinhs = tabs;
    const tab = tabs.find((k, index) => this.tabActive.id === k.id);
    const isCustomize = this.customizeCharts.find((k) => k.id === id);
    if (isCustomize) {
      this.customizeCharts = this.customizeCharts.filter((k) => k.id !== id);
    }
    if (tab) this.tabActive = tab;
    this.saveTablist();
  };

  @action
  public setOpenNewPageTaiChinh = (openNewPageTaiChinh: boolean) => {
    this.openNewPageTaiChinh = openNewPageTaiChinh;
  };

  @action
  public setOpenEditNameTaiChinh = (openEditNameTaiChinh: boolean) => {
    this.openEditNameTaiChinh = openEditNameTaiChinh;
  };

  @action
  public removeTaiChinhValue = () => {
    if (this.tabActive.id !== '1') {
      this.tabTaiChinhs = this.tabTaiChinhs
        .slice()
        .filter((t) => t.id !== this.tabActive.id);
      this.tabActive = this.tabTaiChinhs[0];
    }
    this.openEditNameTaiChinh = false;
    this.saveTablist();
  };

  @action
  public setTabActive = (tabIndex: number) => {
    const tab = this.tabTaiChinhs.find((_, index) => index === tabIndex);
    if (tab) {
      this.tabActive = tab;
    } else {
      this.tabActive = this.tabTaiChinhs[0];
    }
  };

  @action
  public addTabTaiChinh = (pageName: string) => {
    if (this.tabTaiChinhs.length < 10) {
      this.tabTaiChinhs = [
        ...this.tabTaiChinhs,
        {
          index: this.tabTaiChinhs.length,
          id: uuidv4(),
          name: pageName.trim(),
          data: [],
          layout: [],
        },
      ];
      this.tabActive = this.tabTaiChinhs[this.tabTaiChinhs.length - 1];
    }
    this.openNewPageTaiChinh = false;
    this.saveTablist();
  };

  @action
  public updateTabTaiChinhName = (pageName: string) => {
    let tabs = this.tabTaiChinhs.slice();
    tabs = tabs.map((t) => ({
      ...t,
      name: t.id === this.tabActive.id ? pageName.trim() : t.name,
    }));
    this.tabTaiChinhs = tabs;
    const tab = tabs.find((k) => this.tabActive.id === k.id);
    if (tab) this.tabActive = tab;
    this.openEditNameTaiChinh = false;
    this.saveTablist();
  };

  @action
  private fetchDataSuccessCustomize = (dataR: any) => {
    this.dataCustomize = dataR.map((k: any) => ({
      ...k,
      data: k.data.map((r: any) => ({
        labelID: r.itemCode,
        label: r.label,
        unit: r.unit,
        data: r.data,
        displayLevel: r.displayLevel,
      })),
    }));
    this.loadingCustomize = false;
    this.loadData();
  };

  generateArrayLayouts = (charts: any) => {
    let arr = [];
    for (let i = 0; i < charts.length; i++) {
      let obj = charts[i];
      obj.i = `${obj.id}`;
      obj.w = 1;
      obj.h = 1;
      obj.x = i % 3;
      obj.y = Math.floor(i / 3);
      arr.push(obj);
    }
    return arr;
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = {
      ...dataR,
      data: dataR.data.map((k: any) => ({
        ...k,
        data: k.data.map((r: any) => ({
          labelID: r.id,
          label: r.label,
          unit: r.unit,
          data: r.data,
        })),
      })),
    };
    this.getTablist();
  };

  @action
  private getTablist = async () => {
    this.loading = true;
    const queryString: string = `api/setting/chart?code=${this.code}`;
    await apiAuthen
      .get(queryString)
      .then((resp: any) => resp.data)
      .then(this.fetchGetDataSuccess)
      .catch(this.fetchGetDataError);
  };

  @action
  private saveTablist = async () => {
    this.loading = true;
    const queryString: string = 'api/setting/chart';
    await apiAuthen
      .post(queryString, {
        code: this.code,
        content: {
          tabs: this.tabTaiChinhs,
          customizeCharts: this.customizeCharts,
        },
      })
      .then((resp: any) => resp.data)
      .then(this.fetchSaveDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchGetDataSuccess = (dataR: any) => {
    if (dataR && dataR.content) {
      const content: { tabs: TabTaiChinh[]; customizeCharts: Chart[] } =
        dataR.content;
      let tabTaiChinhsdefault: Array<Chart> = [];
      if (this.data.companyForm === 'SECURITIES')
        tabTaiChinhsdefault = tabTaiChinhsdefaultSECURITIES;
      if (this.data.companyForm === 'BANK')
        tabTaiChinhsdefault = tabTaiChinhsdefaultBANK;
      if (this.data.companyForm === 'NON_FINANCE')
        tabTaiChinhsdefault = tabTaiChinhsdefaultNON_FINANCE;
      if (this.data.companyForm === 'INSURANCE')
        tabTaiChinhsdefault = tabTaiChinhsdefaultINSURANCE;
      this.defaultCharts = tabTaiChinhsdefault;
      this.tabTaiChinhs = content.tabs.map((t) => ({
        ...t,
        layout: t.layout.map((l: any) => ({
          ...l,
          x: typeof l.x === 'number' ? l.x : 0,
          y: typeof l.y === 'number' ? l.y : 0,
          w: 1,
          h: 1,
        })),
      }));
      this.tabActive = this.tabTaiChinhs[0];
      this.customizeCharts = content.customizeCharts;
    } else {
      this.buildtablistDefalut();
    }
    this.loading = false;
  };
  @action
  resetTabMacDinh = () => {
    this.buildtablistDefalut(true);
    this.saveTablist();
  };

  @action
  private buildtablistDefalut = (isReset: boolean = false) => {
    let charts: any = [];
    let tabTaiChinhsdefault: Array<Chart> = [];
    if (this.data.companyForm === 'SECURITIES')
      tabTaiChinhsdefault = tabTaiChinhsdefaultSECURITIES;
    if (this.data.companyForm === 'BANK')
      tabTaiChinhsdefault = tabTaiChinhsdefaultBANK;
    if (this.data.companyForm === 'NON_FINANCE')
      tabTaiChinhsdefault = tabTaiChinhsdefaultNON_FINANCE;
    if (this.data.companyForm === 'INSURANCE')
      tabTaiChinhsdefault = tabTaiChinhsdefaultINSURANCE;
    if (this.data && this.data.data.length > 0) {
      for (let i = 0; i < this.data.data.length; i++) {
        const c = tabTaiChinhsdefault.find(
          (k) => k.labels[0] === this.data.data[i].label,
        );
        if (c) charts.push(c);
      }
    }
    this.defaultCharts = tabTaiChinhsdefault;
    const defchartstmp = charts.filter((c: any, index: number) => c.isSelected);

    const layout = this.generateArrayLayouts(defchartstmp);
    if (!isReset) {
      this.tabTaiChinhs = [
        {
          index: 0,
          id: '1',
          name: 'Trang mặc định',
          data: defchartstmp,
          layout,
        },
      ];
      this.customizeCharts = [];
    } else {
      let list = this.tabTaiChinhs;
      list[0] = {
        index: 0,
        id: '1',
        name: 'Trang mặc định',
        data: defchartstmp,
        layout,
      };
      this.tabTaiChinhs = list;
    }
    this.tabActive = this.tabTaiChinhs[0];
  };

  @action
  private fetchSaveDataSuccess = () => {
    // FIXME
    this.loading = false;
    this.loadingCustomize = false;
  };

  @action
  private fetchGetDataError = (error: any) => {
    this.buildtablistDefalut();
    this.loading = false;
    this.loadingCustomize = false;
  };
  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
    this.loadingCustomize = false;
  };
}

export default TabFinanceStore;

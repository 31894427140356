import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';

export interface RecommendedStoreProps {
  loading: boolean;
  data: RecommendedData;
  limit: number;
  loadContentData: (isLoading?: boolean) => void;
  updateLimit: (limit: number) => void;
}

export interface RecommendedData {
  totalOpen: number;
  totalClose: number;
  winRate: number;
  totalProfit: number;
  totalLoss: number;
  rewardRick: number;
  pctProfit: number;
  pctLoss: number;
  stocks: StocksData[];
}

export interface StocksData {
  no: number;
  code: string;
  adClose: number;
  buyPrice: number;
  buyDate: string;
  closePrice: string;
  closeDate: string;
  profit: number;
  target: number;
  stopLoss: number;
  action: number;
}

class RecommendedStore implements RecommendedStoreProps {
  private apiPath: string = 'api/recommendations';

  @observable
  data: RecommendedData = {
    totalOpen: 0,
    totalClose: 0,
    winRate: 0,
    totalProfit: 0,
    totalLoss: 0,
    rewardRick: 0,
    pctProfit: 0,
    pctLoss: 0,
    stocks: [],
  };

  // @observable
  // financeData: FinanceData[] = [];

  // @observable
  // techniqueData: TechniqueData[] = [];

  @observable
  loading: boolean = false;

  @observable
  activeTab: number = 0;

  @observable
  cat: number = 1;

  @observable
  floor: number = 0;

  @observable
  type: number = 1;

  @observable
  limit: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadContentData = async (isLoading: boolean = true) => {
    this.loading = isLoading;
    const queryString: string = `${this.apiPath}?limit=${this.limit}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public updateLimit = (data: number) => {
    this.limit = data;
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.loading = false;
    this.data = dataR;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  // @action
  // public updateData = (generalData: any[], financeData: any[], techniqueData: any[]) => {
  //   this.generalData = generalData;
  //   this.financeData = financeData;
  //   this.techniqueData = techniqueData;
  // };

  // @action
  // public setActiveTab = (tabIndex: number) => {
  //   this.activeTab = tabIndex;
  // };
}

export default new RecommendedStore();

const AppLockIcon = (props: any) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.46459 9.0845C7.18668 9.08558 6.91682 9.17791 6.69649 9.34729C6.47616 9.51667 6.31756 9.75372 6.24508 10.022C6.17259 10.2903 6.19023 10.575 6.29528 10.8323C6.40032 11.0896 6.58697 11.3052 6.82652 11.4461V13.2553H8.11822V11.4461C8.30774 11.334 8.46503 11.1748 8.5748 10.9839C8.68456 10.793 8.74306 10.5769 8.74461 10.3567V10.3567C8.74462 10.189 8.71145 10.0229 8.64703 9.86807C8.58261 9.7132 8.4882 9.57261 8.36923 9.45437C8.25027 9.33612 8.1091 9.24257 7.95384 9.1791C7.79859 9.11562 7.63232 9.08347 7.46459 9.0845V9.0845Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2479 6.16309H2.68076C1.7525 6.16309 1 6.91559 1 7.84385V14.493C1 15.4212 1.7525 16.1737 2.68076 16.1737H12.2479C13.1761 16.1737 13.9286 15.4212 13.9286 14.493V7.84385C13.9286 6.91559 13.1761 6.16309 12.2479 6.16309Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.32227 6.16289V5.14743C3.32227 4.04746 3.75923 2.99255 4.53702 2.21475C5.31481 1.43696 6.36973 1 7.4697 1C8.56966 1 9.62458 1.43696 10.4024 2.21475C11.1802 2.99255 11.6171 4.04746 11.6171 5.14743V6.16289"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default AppLockIcon;

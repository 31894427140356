import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';

export interface CG3TopRRGStoreProps {
  data: Array<CGTOPData>;
  loadData: () => void;
  loading: boolean;
  filter: IFilter;
  updateFiltes: (filter: IFilter) => void;
  updateData: (data: any) => void;
  timePeroid: string;
  setTimePeroid: (timePeroid: string) => void;
}

export interface CGTOPData {
  code: string;
  adClose: number;
  pctChange: number;
  nmVolume: number;
  rsRating: number;
}

export interface IFilter {
  type: string;
  adCloseFrom: number;
  adCloseTo: number;
  valFrom: number;
  valTo: number;
  capFrom: number;
  capTo: number;
}

class CG3TopRRGStore implements CG3TopRRGStoreProps {
  private apiPath: string = '/api/stock/top_list/show';

  @observable
  data: Array<CGTOPData> = [];

  @observable
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  @observable
  filter: IFilter = {
    type: '0',
    adCloseFrom: 10,
    adCloseTo: 500,
    valFrom: 100,
    valTo: 200000,
    capFrom: 1000,
    capTo: 200000,
  };

  @observable
  timePeroid: string = 'Ngày';

  @action
  public setTimePeroid = (timePeroid: string) => {
    this.timePeroid = timePeroid;
    this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    const week = this.timePeroid === 'Tuần' ? 1 : 0;
    const queryString: string = `${this.apiPath}?type=${this.filter.type}&week=${week}&adclosefrom=${this.filter.adCloseFrom}&adcloseto=${this.filter.adCloseTo}&valfrom=${this.filter.valFrom}&valto=${this.filter.valTo}&capfrom=${this.filter.capFrom}&capto=${this.filter.capTo}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public updateFiltes = async (filter: IFilter) => {
    this.filter = filter;
    this.loadData();
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };

  @action
  public updateData = (data: any[]) => {
    this.data = data;
  };
}

export default new CG3TopRRGStore();

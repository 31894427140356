import { observable, action, makeAutoObservable } from 'mobx';
import api from '../request';

export interface CompanyInforStoreProps {
  data?: CompanyInforData;
  code: string;
  loadData: () => void;
  setCode: (code: string) => void;
  loading: boolean;
  type: number;
  setType: (type: number) => void;
  updateData: (data: CompanyInforData) => void;
}

export interface CompanyInforData {
  name: string;
  code: string;
  adAverage: number;
  adClose: number;
  adHigh: number;
  adLow: number;
  nmVolume: number;
  pctChange: number;
  adChange: number;
  volMa20: number;
  basicPrice: number;
  business: Array<string>;
  desc: Array<string>;
  history: Array<string>;
  ceilingPrice: number;
  estimateVol: number;
  floorPrice: number;
  floor: string;

  low: number;
  open: number;
  high: number;
  close: number;
  declines: number;
  floorStocks: number;
  noChange: number;
  advances: number;
  ceilingStocks: number;
  totalStocks: number;
  info: {
    address: string;
    companyName: string;
    companyNameEng: string;
    fax: string;
    phone: string;
    shortName: string;
    website: string;
  };
  leader: Array<{ name: string; group: string }>;
}

class CompanyInforStore implements CompanyInforStoreProps {
  private apiPath: string = 'api/company/info';
  private apiIndexPath: string = 'api/company/market_info';

  @observable
  loading: boolean = true;

  @observable
  data?: CompanyInforData;

  @observable
  code: string = 'MBS';

  constructor() {
    makeAutoObservable(this);
  }

  @observable
  type: number = 1; //1=STOCK,2=INDEX

  @action
  public setType = (type: number) => {
    this.type = type;
  };

  @action
  public updateData = (data: CompanyInforData) => {
    this.data = data;
  };

  @action
  public setCode = (code: string) => {
    this.code = code;
    this.loadData();
  };

  @action
  public loadData = async () => {
    this.loading = true;
    let queryString: string = `${this.apiPath}?code=${this.code}`;
    if (this.type === 2) queryString = `${this.apiIndexPath}?code=${this.code}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    if (this.type === 2) {
      this.data = dataR;
      if (this.data !== undefined)
        this.data.totalStocks =
          this.data.declines + this.data.noChange + this.data.advances;
    } else this.data = dataR;
    this.loading = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
  };
}

export default CompanyInforStore;

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AppNumberFormat } from './AppNumberFormat';
import { configColors } from '../../const/colors';

const marksDefault = [
  {
    value: 10,
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 150,
  },
  {
    value: 200,
  },
  {
    value: 250,
  },
  {
    value: 300,
  },
  {
    value: 350,
  },
  {
    value: 400,
  },
  {
    value: 450,
  },
  {
    value: 500,
  },
];
const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: configColors.appSlider.color,
  height: 2,
  marginLeft: 15,
  marginRight: 15,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: configColors.appSlider.thumb,
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: 3,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color:
        theme.palette.mode === 'dark'
          ? configColors.appSlider.valueLabelDark
          : configColors.appSlider.valueLabelLight,
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: configColors.appSlider.rail,
  },
  '& .MuiSlider-mark': {
    backgroundColor: configColors.appSlider.rail,
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

export const AppSlider: React.FC<{
  title: string;
  value: number | number[] | undefined;
  onChange: (value: number[]) => void;
  marks?: { value: number }[];
  min?: number;
  max?: number;
  minValue?: number;
  maxValue?: number;
  minName: string;
  maxName: string;
  step?: number;
  control?: any;
}> = ({
  title,
  value,
  marks,
  min,
  max,
  onChange,
  step,
  control,
  minValue,
  maxValue,
  minName,
  maxName,
}) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number[]);
  };
  return (
    <>
      <Typography variant="h6" fontSize={13} gutterBottom>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box height={30} marginRight="3px">
          <AppNumberFormat
            control={control}
            name={minName}
            defaultValue={minValue}
            min={0}
          />
        </Box>
        <IOSSlider
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          valueLabelDisplay="on"
          marks={marks ? marks : marksDefault}
        />
        <Box width={200} height={30} marginLeft="3px">
          <AppNumberFormat
            control={control}
            name={maxName}
            defaultValue={maxValue}
            min={0}
          />
        </Box>
      </Box>
    </>
  );
};

import { Rectangle } from 'recharts';

export const ChartCustomCursor = (props: any) => {
  const { x, y, width, height } = props;
  return (
    <Rectangle
      fill="rgba(243, 243, 243, 0.2)"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};

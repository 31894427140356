const AppGuideIcon = (props: any) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2031 12.2188C21.0048 12.2188 20.8438 12.0577 20.8438 11.8594V1.4375H12.5781C11.9837 1.4375 11.5 1.92122 11.5 2.51562C11.5 2.714 11.339 2.875 11.1406 2.875C10.9422 2.875 10.7812 2.714 10.7812 2.51562C10.7812 1.52447 11.587 0.71875 12.5781 0.71875H21.2031C21.4015 0.71875 21.5625 0.87975 21.5625 1.07812V11.8594C21.5625 12.0577 21.4015 12.2188 21.2031 12.2188Z"
        fill="white"
      />
      <path
        d="M8.98438 17.9688H1.07812C0.87975 17.9688 0.71875 17.8077 0.71875 17.6094C0.71875 17.411 0.87975 17.25 1.07812 17.25H8.98438C9.18275 17.25 9.34375 17.411 9.34375 17.6094C9.34375 17.8077 9.18275 17.9688 8.98438 17.9688Z"
        fill="white"
      />
      <path
        d="M11.1406 12.2188C10.9423 12.2188 10.7812 12.0577 10.7812 11.8594V2.51562C10.7812 1.92122 10.2975 1.4375 9.70312 1.4375H1.4375V4.67188C1.4375 4.87025 1.2765 5.03125 1.07812 5.03125C0.87975 5.03125 0.71875 4.87025 0.71875 4.67188V1.07812C0.71875 0.87975 0.87975 0.71875 1.07812 0.71875H9.70312C10.6943 0.71875 11.5 1.52447 11.5 2.51562V11.8594C11.5 12.0577 11.339 12.2188 11.1406 12.2188Z"
        fill="white"
      />
      <path
        d="M6.82812 5.75H8.98438C9.18275 5.75 9.34375 5.911 9.34375 6.10938C9.34375 6.30703 9.18275 6.46875 8.98438 6.46875H6.82812C6.62975 6.46875 6.46875 6.30703 6.46875 6.10938C6.46875 5.911 6.62975 5.75 6.82812 5.75Z"
        fill="white"
      />
      <path
        d="M3.23438 8.625H8.98438C9.18275 8.625 9.34375 8.786 9.34375 8.98438C9.34375 9.18203 9.18275 9.34375 8.98438 9.34375H3.23438C3.036 9.34375 2.875 9.18203 2.875 8.98438C2.875 8.786 3.036 8.625 3.23438 8.625Z"
        fill="white"
      />
      <path
        d="M3.33333 7H8.66667C8.85067 7 9 7.224 9 7.5C9 7.775 8.85067 8 8.66667 8H3.33333C3.14933 8 3 7.775 3 7.5C3 7.224 3.14933 7 3.33333 7Z"
        fill="white"
      />
      <path
        d="M3.23438 11.5H4.67188C4.87025 11.5 5.03125 11.661 5.03125 11.8594C5.03125 12.057 4.87025 12.2188 4.67188 12.2188H3.23438C3.036 12.2188 2.875 12.057 2.875 11.8594C2.875 11.661 3.036 11.5 3.23438 11.5Z"
        fill="white"
      />
      <path
        d="M3.23438 10.0625H8.98438C9.18275 10.0625 9.34375 10.2235 9.34375 10.4219C9.34375 10.6195 9.18275 10.7812 8.98438 10.7812H3.23438C3.036 10.7812 2.875 10.6195 2.875 10.4219C2.875 10.2235 3.036 10.0625 3.23438 10.0625Z"
        fill="white"
      />
      <path
        d="M6.10938 11.5H8.98438C9.18275 11.5 9.34375 11.661 9.34375 11.8594C9.34375 12.057 9.18275 12.2188 8.98438 12.2188H6.10938C5.911 12.2188 5.75 12.057 5.75 11.8594C5.75 11.661 5.911 11.5 6.10938 11.5Z"
        fill="white"
      />
      <path
        d="M3.23438 5.75H5.39062C5.589 5.75 5.75 5.911 5.75 6.10938C5.75 6.30703 5.589 6.46875 5.39062 6.46875H3.23438C3.036 6.46875 2.875 6.30703 2.875 6.10938C2.875 5.911 3.036 5.75 3.23438 5.75Z"
        fill="white"
      />
      <path
        d="M13.2969 5.75H19.0469C19.2453 5.75 19.4062 5.911 19.4062 6.10938C19.4062 6.30703 19.2453 6.46875 19.0469 6.46875H13.2969C13.0985 6.46875 12.9375 6.30703 12.9375 6.10938C12.9375 5.911 13.0985 5.75 13.2969 5.75Z"
        fill="white"
      />
      <path
        d="M16.8906 8.625H19.0469C19.2452 8.625 19.4062 8.786 19.4062 8.98438C19.4062 9.18203 19.2452 9.34375 19.0469 9.34375H16.8906C16.6922 9.34375 16.5312 9.18203 16.5312 8.98438C16.5312 8.786 16.6922 8.625 16.8906 8.625Z"
        fill="white"
      />
      <path
        d="M16.8906 7.1875H19.0469C19.2452 7.1875 19.4062 7.3485 19.4062 7.54688C19.4062 7.74453 19.2452 7.90625 19.0469 7.90625H16.8906C16.6922 7.90625 16.5312 7.74453 16.5312 7.54688C16.5312 7.3485 16.6922 7.1875 16.8906 7.1875Z"
        fill="white"
      />
      <path
        d="M16.8906 10.0625H19.0469C19.2452 10.0625 19.4062 10.2235 19.4062 10.4219C19.4062 10.6195 19.2452 10.7812 19.0469 10.7812H16.8906C16.6922 10.7812 16.5312 10.6195 16.5312 10.4219C16.5312 10.2235 16.6922 10.0625 16.8906 10.0625Z"
        fill="white"
      />
      <path
        d="M3.23438 3.59375C3.43285 3.59375 3.59375 3.43285 3.59375 3.23438C3.59375 3.0359 3.43285 2.875 3.23438 2.875C3.0359 2.875 2.875 3.0359 2.875 3.23438C2.875 3.43285 3.0359 3.59375 3.23438 3.59375Z"
        fill="white"
      />
      <path
        d="M4.67188 3.59375C4.87035 3.59375 5.03125 3.43285 5.03125 3.23438C5.03125 3.0359 4.87035 2.875 4.67188 2.875C4.4734 2.875 4.3125 3.0359 4.3125 3.23438C4.3125 3.43285 4.4734 3.59375 4.67188 3.59375Z"
        fill="white"
      />
      <path
        d="M6.10938 3.59375C6.30785 3.59375 6.46875 3.43285 6.46875 3.23438C6.46875 3.0359 6.30785 2.875 6.10938 2.875C5.9109 2.875 5.75 3.0359 5.75 3.23438C5.75 3.43285 5.9109 3.59375 6.10938 3.59375Z"
        fill="white"
      />
      <path
        d="M16.1719 3.59375C16.3704 3.59375 16.5312 3.43285 16.5312 3.23438C16.5312 3.0359 16.3704 2.875 16.1719 2.875C15.9734 2.875 15.8125 3.0359 15.8125 3.23438C15.8125 3.43285 15.9734 3.59375 16.1719 3.59375Z"
        fill="white"
      />
      <path
        d="M17.6094 3.59375C17.8079 3.59375 17.9688 3.43285 17.9688 3.23438C17.9688 3.0359 17.8079 2.875 17.6094 2.875C17.4109 2.875 17.25 3.0359 17.25 3.23438C17.25 3.43285 17.4109 3.59375 17.6094 3.59375Z"
        fill="white"
      />
      <path
        d="M19.0469 3.59375C19.2454 3.59375 19.4062 3.43285 19.4062 3.23438C19.4062 3.0359 19.2454 2.875 19.0469 2.875C18.8484 2.875 18.6875 3.0359 18.6875 3.23438C18.6875 3.43285 18.8484 3.59375 19.0469 3.59375Z"
        fill="white"
      />
      <path
        d="M21.2038 22.2151C21.0564 22.2151 20.9177 22.1231 20.8652 21.9765C20.7984 21.7889 20.8961 21.5841 21.083 21.5172C21.3698 21.4152 21.5631 21.1442 21.5631 20.8438V14.7344C21.5631 14.3376 21.2411 14.0156 20.8444 14.0156C20.4476 14.0156 20.1256 14.3376 20.1256 14.7344C20.1256 14.9328 19.9646 15.0938 19.7663 15.0938C19.5679 15.0938 19.4069 14.9328 19.4069 14.7344V14.0156C19.4069 13.6189 19.0849 13.2969 18.6881 13.2969C18.2914 13.2969 17.9694 13.6189 17.9694 14.0156C17.9694 14.214 17.8084 14.375 17.61 14.375C17.4116 14.375 17.2506 14.214 17.2506 14.0156V13.6562C17.2506 13.2595 16.9286 12.9375 16.5319 12.9375C16.1351 12.9375 15.8131 13.2595 15.8131 13.6562C15.8131 13.8546 15.6521 14.0156 15.4538 14.0156C15.2554 14.0156 15.0944 13.8546 15.0944 13.6562V8.625C15.0944 8.22825 14.7724 7.90625 14.3756 7.90625C13.9789 7.90625 13.6569 8.22825 13.6569 8.625V16.8906C13.6569 17.0581 13.5404 17.204 13.3766 17.2407C13.2105 17.2809 13.0459 17.1961 12.9726 17.0459L11.7206 14.4275C11.6013 14.1694 11.3899 13.8431 11.0529 13.7389C10.8329 13.6699 10.5799 13.6972 10.2967 13.8216L11.3439 17.8789C11.6659 18.6207 12.7089 20.7755 13.4456 21.2067C13.6166 21.3073 13.6741 21.5273 13.5742 21.6991C13.4736 21.8694 13.2537 21.9255 13.0819 21.8277C12.0102 21.1995 10.7999 18.4252 10.6654 18.1103L9.5291 13.7245C9.48885 13.5693 9.55642 13.4068 9.69442 13.3249C10.3952 12.9123 10.935 12.9497 11.267 13.0525C11.7321 13.1977 12.1144 13.5678 12.3703 14.1213L12.9381 15.3065V8.625C12.9381 7.83222 13.5829 7.1875 14.3756 7.1875C15.1684 7.1875 15.8131 7.83222 15.8131 8.625V12.4121C16.0244 12.2892 16.2703 12.2188 16.5319 12.2188C17.0544 12.2188 17.5115 12.4983 17.7638 12.9159C18.0139 12.7053 18.3367 12.5781 18.6881 12.5781C19.3142 12.5781 19.8482 12.9806 20.0451 13.5405C20.2737 13.3867 20.549 13.2969 20.8444 13.2969C21.6372 13.2969 22.2819 13.9416 22.2819 14.7344V20.8438C22.2819 21.4475 21.8974 21.9909 21.3245 22.1943C21.2843 22.2087 21.244 22.2151 21.2038 22.2151Z"
        fill="white"
      />
    </svg>
  );
};
export default AppGuideIcon;

import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { AppBaseUrlWS } from '../const/config';

// @ts-ignore
window.Pusher = Pusher;

const options = {
  broadcaster: 'pusher',
  key: 'alpha_stock',
  cluster: 'ap2',
  wsPort: 2053,
  wssPort: 2053,
  wsHost: AppBaseUrlWS,
  disableStats: true,
  forceTLS: true,
};

export interface WSClientProps {
  listen: (
    callBack: (payload: any) => void,
    channel: string,
    event: string,
  ) => void;
  leaveChannel: (channel: string) => void;
}

class WSClient implements WSClientProps {
  private Echo = new Echo(options);

  public listen = (
    callBack: (payload: any) => void,
    channel: string,
    event: string,
  ) => {
    console.log(`Start socket: ${channel}`);
    this.Echo.channel(channel).listen(event, (payload: any) => {
      callBack(payload);
    });
    const that = this;
    return function cleanUp() {
      that.Echo.leaveChannel(channel);
    };
  };

  public leaveChannel = (channel: string) => {
    console.log(`End socket: ${channel}`);
    this.Echo.leaveChannel(channel);
  };
}

export default new WSClient();

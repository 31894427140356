import Select, { createFilter, StylesConfig, components } from 'react-select';
import { defaultTheme } from 'react-select';
import React from 'react';
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Virtuoso } from 'react-virtuoso';

const { colors } = defaultTheme;
const CustomOption = ({ children, ...props }: any) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  const renderChildren = () => {
    if (children) {
      const items = children.split(';');
      return (
        <div
          style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            color: 'white',
          }}
        >
          {items.map((t: any, index: number) => (
            <span
              style={{
                display: 'inline-block',
                width: items.length === 3 && index === 1 ? '60%' : '20%',
                textAlign:
                  index === 2 || (items.length === 2 && index === 1)
                    ? 'right'
                    : 'left',
              }}
              key={index}
            >
              {t}
            </span>
          ))}
        </div>
      );
    }
    return null;
  };
  return (
    <div style={{ background: '#3D4149' }}>
      <components.Option {...newProps}>{renderChildren()}</components.Option>
    </div>
  );
};

const MenuList = React.memo((props: any) => {
  const rows = props.children;
  const selectProps = props.selectProps;
  return (
    <Virtuoso
      style={{
        width: 300,
        height: rows.length * 34,
        backgroundColor: '#333333',
        maxHeight: selectProps.maxMenuHeight,
        minHeight: selectProps.minMenuHeight,
      }}
      data={rows}
      itemContent={(index, user) => (
        <div
          style={{
            fontSize: 13,
            // color: 'black',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {user}
        </div>
      )}
    />
  );
});

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  matchFromStart: 'any',
  stringify: (option: any) => `${option.label.split(';')[0]}`,
};

const MuteOption = ['VNMID', 'VNSML', 'VNFINLEAD', 'VNDIAMOND'];

const StockSelection: React.FC<any> = ({
  name,
  options,
  valueKey,
  labelKey,
  idKey,
  control,
  onChangeComplete,
  rules,
  isMultil,
  isRealCom,
  defaultValue,
  width,
  isDisabled,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [placeholderAnime, setPlaceholder] = React.useState('');
  const text = placeholder || 'Tìm mã';
  React.useEffect(() => {
    let i = 0;
    let timer: any;
    function startMarquee() {
      if (i < text.length) {
        setPlaceholder((prev) => text.slice(0, i + 1));
        i++;
        timer = setTimeout(startMarquee, 60);
      } else {
        setPlaceholder(text);
        i = 0;
        timer = setTimeout(startMarquee, 3000); // dừng lại 3 giây
      }
    }

    timer = setTimeout(startMarquee, 60);

    return () => clearTimeout(timer);
  }, []);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  let data = React.useMemo(
    () =>
      valueKey && labelKey
        ? options
            .filter(function (obj: any) {
              return !MuteOption.includes(obj.code);
            })
            .map((d: any) => ({
              value: d[valueKey],
              label: idKey ? `${d[idKey]} - ${d[labelKey]}` : d[labelKey],
            }))
        : options,
    [options, labelKey, valueKey, idKey],
  );
  const selectStyles: StylesConfig<any, false> = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (provided) => ({
      ...provided,
      width: width,
      fontSize: 12,
      height: 30,
      minHeight: placeholder ? 35 : 30,
      background: '#41495C',
      paddingLeft: 5,
      borderColor: 'transparent',
      flexDirection: 'row-reverse',
      zIndex: 3,
    }),
    menu: () => ({ backgroundColor: '#41495C', zIndex: 9999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: 11,
        backgroundColor: isFocused ? '#41495C' : undefined,
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'rgba(255, 255, 255, 0.8)',
      };
    },
    input: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.8)',
    }),
  };

  return (
    <Box
      className={'stock-selection'}
      sx={{
        display: 'flex',
        width: { width },
        height: 30,
        cursor: 'pointer',
        borderRadius: '5px',
        // paddingTop: '4px',
      }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={rules}
        render={({ field }) => {
          return (
            <Select
              {...field}
              menuPortalTarget={document.body}
              className="react-select-container"
              classNamePrefix="react-select"
              isDisabled={isDisabled}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Option: CustomOption,
                MenuList,
              }}
              options={data}
              placeholder={placeholderAnime}
              filterOption={createFilter(filterConfig)}
              styles={selectStyles}
              menuIsOpen={isOpen}
              onBlur={() => setIsOpen(false)}
              onInputChange={(itemChange: any) => {
                if (itemChange && itemChange.length > 0) setIsOpen(true);
              }}
              onChange={(item?: any) => {
                if (isMultil) {
                  field.onChange(item ? item.map((i: any) => i.value) : []);
                  onChangeComplete &&
                    onChangeComplete(item ? item.map((i: any) => i.value) : []);
                } else {
                  field.onChange(item ? item.value : null);
                  toggleOpen();
                  onChangeComplete &&
                    onChangeComplete(item ? item.label : null);
                }
              }}
            />
          );
        }}
      />
    </Box>
  );
};

// styled components

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 20, width: 28 }}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0177 11.1533L9.85252 8.17129C10.7151 7.1774 11.1391 5.90919 11.0371 4.62898C10.935 3.34877 10.3145 2.1545 9.304 1.2932C8.29351 0.431905 6.97031 -0.0305245 5.60808 0.00156501C4.24585 0.0336546 2.94883 0.557808 1.98529 1.46561C1.02175 2.37341 0.465409 3.5954 0.431349 4.87882C0.397289 6.16224 0.888113 7.4089 1.8023 8.36093C2.71648 9.31296 3.98408 9.89753 5.3429 9.99372C6.70173 10.0899 8.04781 9.69035 9.10272 8.87771L12.2679 11.8598C12.3679 11.9508 12.5018 12.0011 12.6409 12C12.7799 11.9988 12.9129 11.9463 13.0112 11.8537C13.1095 11.761 13.1653 11.6357 13.1665 11.5048C13.1677 11.3738 13.1143 11.2476 13.0177 11.1533ZM5.74932 9.0086C4.91031 9.0086 4.09013 8.7742 3.39252 8.33503C2.69491 7.89587 2.15118 7.27167 1.83011 6.54136C1.50903 5.81106 1.42502 5.00745 1.5887 4.23217C1.75239 3.45688 2.15641 2.74473 2.74968 2.18578C3.34296 1.62683 4.09883 1.24618 4.92172 1.09197C5.74462 0.937752 6.59757 1.0169 7.37271 1.3194C8.14786 1.6219 8.81039 2.13417 9.27652 2.79143C9.74265 3.44869 9.99145 4.22141 9.99145 5.01189C9.99019 6.07152 9.54284 7.0874 8.74756 7.83668C7.95228 8.58595 6.87402 9.00741 5.74932 9.0086Z"
        fill="white"
      />
    </svg>
  </div>
);

export default StockSelection;

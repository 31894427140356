import { observable, action, makeAutoObservable } from 'mobx';
import api from './request';
import moment from 'moment';

export const TimePeroidTypes = ['1m', '3m', '6m', '1y', '3y', '5y'];

export interface MarketChartZoomProps {
  dataMarket: Array<MarketData>;
  loadData: (isLoading?: boolean) => void;
  setTimePeroidMarket: (timePeroid: string) => void;
  setDataEndNodeZoom: (endNote: MarketTableData) => void;
  dataCodeZoom: string;
  timePeroidMarket: string;
  loading: boolean;
}

export interface MarketData {
  x: string;
  y: number;
}

export interface MarketTableData {
  code: string;
  change: number;
  pctChange: number;
  value: number;
  date: string;
}

class MarketChartZoomStore implements MarketChartZoomProps {
  private apiPath: string = 'charts_json';
  private apiPathEndNode: string = 'api/market';

  @observable
  dataMarket: Array<MarketData> = [];

  @observable
  timePeroidMarket: string = '6m';

  @observable
  loading: boolean = false;

  @observable
  dataCodeZoom: string = 'vnindex';

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public setTimePeroidMarket = (timePeroid: string) => {
    this.timePeroidMarket = timePeroid;
    this.loadData();
  };

  @action
  public setDataEndNodeZoom = (endNote: MarketTableData) => {
    if (this.dataMarket.length > 0) {
      if (
        this.dataMarket[this.dataMarket.length - 1].x === endNote.date &&
        this.dataMarket[this.dataMarket.length - 1].y !== endNote.value &&
        !!endNote.date
      ) {
        const tmpData = this.dataMarket.filter((x) => x.x !== endNote.date);
        this.dataMarket = [...tmpData, { x: endNote.date, y: endNote.value }];
      } else if (
        this.dataMarket[this.dataMarket.length - 1].x !== endNote.date &&
        this.dataMarket[this.dataMarket.length - 1].y !== endNote.value &&
        !!endNote.date
      ) {
        if (
          moment(this.dataMarket[this.dataMarket.length - 1].x).isBefore(
            moment(endNote.date),
          )
        )
          this.dataMarket = [
            ...this.dataMarket,
            { x: endNote.date, y: endNote.value },
          ];
      }
    }
  };

  @action
  public loadData = (isLoading: boolean = true) => {
    //if (this.dataMarket.length <= 0) {
    this.loading = isLoading;
    const day = moment().milliseconds().toString();
    const queryString: string = `${
      this.apiPath
    }/${this.dataCodeZoom.toLowerCase()}_${
      this.timePeroidMarket
    }.json?day=${day}`;
    api
      .get(queryString)
      .then((resp) => resp.data.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
    //}
  };

  @action
  private fetchDataSuccess = (data: Array<{ date: string; value: number }>) => {
    const convertData: Array<MarketData> = data.map((d) => ({
      x: d.date,
      y: d.value,
    }));
    this.dataMarket = convertData;
    const queryString: string = `${this.apiPathEndNode}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataEndNodeSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataEndNodeSuccess = (data: any) => {
    const endNodeZoom = data.find(
      (d: any) => d.code.toLowerCase() === 'vnindex',
    );
    this.setDataEndNodeZoom(endNodeZoom);
    this.loading = false;
  };
  @action
  private fetchDataError = (error: any) => {
    this.loading = false;
    console.error(error);
  };
}

export default new MarketChartZoomStore();

const AppUpgradeIcon = (props: any) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5611_7343)">
        <path d="M12.4458 5.5008C12.8141 5.29193 13.1028 4.96703 13.2669 4.5768C13.4311 4.18657 13.4614 3.75298 13.3532 3.34371C13.245 2.93443 13.0043 2.5725 12.6687 2.3144C12.3332 2.0563 11.9216 1.91656 11.4982 1.91699C11.0749 1.91742 10.6636 2.058 10.3286 2.31678C9.99353 2.57556 9.75359 2.93798 9.64621 3.34747C9.53882 3.75697 9.57003 4.19049 9.73496 4.58038C9.89989 4.97028 10.1893 5.29459 10.5579 5.50271L10.5483 5.51997C9.92063 6.83001 9.04854 8.70642 7.70209 9.4108C6.59617 9.98867 5.00917 9.6983 3.83042 9.48172C3.81151 9.21368 3.71786 8.95631 3.56008 8.73881C3.40231 8.52131 3.18673 8.35238 2.93781 8.25119C2.68888 8.15001 2.41659 8.12062 2.1518 8.16635C1.88702 8.21209 1.64036 8.33112 1.43981 8.50995C1.23925 8.68877 1.09283 8.92023 1.01716 9.17805C0.941493 9.43588 0.939601 9.70975 1.0117 9.9686C1.0838 10.2274 1.22701 10.4609 1.42507 10.6425C1.62314 10.8241 1.86813 10.9465 2.13225 10.9959L4.95742 18.2888C5.16694 18.8295 5.53504 19.2941 6.0134 19.6218C6.49177 19.9495 7.05804 20.1249 7.63788 20.125H15.362C15.9419 20.1249 16.5082 19.9495 16.9865 19.6218C17.4649 19.2941 17.833 18.8295 18.0425 18.2888L20.8667 10.9959C21.1292 10.947 21.3728 10.8261 21.5704 10.6465C21.768 10.467 21.9117 10.236 21.9854 9.97939C22.059 9.72279 22.0598 9.45075 21.9876 9.19374C21.9154 8.93673 21.773 8.70491 21.5764 8.52426C21.3799 8.34361 21.1369 8.22126 20.8747 8.17093C20.6125 8.1206 20.3415 8.14427 20.092 8.23929C19.8426 8.33431 19.6245 8.49693 19.4622 8.70893C19.2999 8.92092 19.1999 9.17391 19.1733 9.43955C17.9668 9.59576 16.4114 9.79509 15.2978 9.21338C13.9782 8.52338 13.0937 6.77634 12.4458 5.5008Z" fill="#F2BB0E" />
      </g>
      <defs>
        <clipPath id="clip0_5611_7343">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default AppUpgradeIcon;

import { observable, action, makeAutoObservable, runInAction } from 'mobx';
import NotificationStore, { NotificationProps } from './notificationStore';
import api from './request';
import WSClient, { WSClientProps } from './wsClient';

export interface TopListProps {
  data: Array<TopListData>;
  loadData: () => void;
  updateData: (data: Array<TopListData>) => void;
  loadDataAction: () => void;
  updateDataAction: (data: any) => void;
  loadingUpdate: boolean;
  loading: boolean;
  dataAction: string;
  startWebSocket: () => void;
  endWebSocket: () => void;
}

export interface TopListData {
  code: string;
  updated_at: string;
  adClose: number;
  adClose3m: number;
  adClose6m: number;
  adClose9m: number;
  adClose12m: number;
  pctChange: number;
  nmVolume: number;
  rsValue: number;
  rsRating: number;
  order: number;
  statusAdClose?: 0 | 1 | 2;
  statusPctChange?: 0 | 1 | 2;
  statusNmVolume?: 0 | 1 | 2;
  ceilingPrice: number;
  floorPrice: number;
}

class TopListStore implements TopListProps {
  private noti: NotificationProps = NotificationStore;
  private apiPath: string = 'api/stock/top_list';
  private apiPathShow: string = 'api/stock/top_list/show';

  private echoChannel: string = 'TopListData';

  private wsClient: WSClientProps = WSClient;

  @observable
  data: Array<TopListData> = [];

  @observable
  loadingUpdate: boolean = false;

  @observable
  dataAction: string = '';

  @observable
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadData = () => {
    this.loading = true;
    const queryString: string = `/${this.apiPathShow}`;
    api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDetailsNewSuccess)
      .catch(this.fetchDataError);
  };
  @action
  public loadDataAction = () => {
    this.loadingUpdate = true;
    api
      .get(`/${this.apiPath}`)
      .then((resp) => resp.data)
      .then((res) => {
        runInAction(() => {
          this.dataAction = res;
          this.loadingUpdate = false;
        });
      })
      .catch(this.fetchDataError);
  };

  @action
  private fetchDetailsNewSuccess = (data: any) => {
    this.data = data;
    this.loading = false;
  };

  @action
  public updateDataAction = (data: any) => {
    this.loadingUpdate = true;
    const queryString: string = `/${this.apiPath}`;
    api
      .post(queryString, data)
      .then(this.updateDataActionSuccess)
      .catch(this.updateDataError);
  };

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  public updateData = (data: Array<TopListData>) => {
    this.data = data;
  };

  @action
  private fetchDataSocketSuccess = (data: any) => {
    if (this.data.length <= 0) {
      this.data = data;
    } else {
      const oldData = this.data.slice();
      let newData: Array<TopListData> = [];
      let currentData: Array<TopListData> = [];
      data.forEach((element: any) => {
        const isElement = oldData.find((k) => k.code === element.code);
        if (!isElement) {
          newData.push({
            ...element,
            adClose: parseFloat(element.adClose),
            pctChange: parseFloat(element.pctChange),
            nmVolume: parseFloat(element.nmVolume),
          });
        }
      });
      oldData.forEach((element) => {
        const isCurrent = data.find((k: any) => k.code === element.code);
        if (isCurrent) {
          currentData.push({
            ...isCurrent,
            adClose: parseFloat(isCurrent.adClose),
            pctChange: parseFloat(isCurrent.pctChange),
            nmVolume: parseFloat(isCurrent.nmVolume),
            statusAdClose:
              parseFloat(isCurrent.adClose.toString()) >
              parseFloat(element.adClose.toString())
                ? 1
                : parseFloat(isCurrent.adClose.toString()) <
                  parseFloat(element.adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(isCurrent.pctChange.toString()) >
              parseFloat(element.pctChange.toString())
                ? 1
                : parseFloat(isCurrent.pctChange.toString()) <
                  parseFloat(element.pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(isCurrent.nmVolume.toString()) >
              parseFloat(element.nmVolume.toString())
                ? 1
                : parseFloat(isCurrent.nmVolume.toString()) <
                  parseFloat(element.nmVolume.toString())
                ? 2
                : 0,
          });
        }
      });
      this.data = [...currentData, ...newData];
    }
  };

  @action
  private updateDataActionSuccess = (data: any) => {
    this.loadingUpdate = false;
    this.loadData();
    this.noti.showSuccessMessage('Success');
  };

  @action
  private updateDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loadingUpdate = false;
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    console.error(error);
    this.loading = false;
    this.noti.showErrorMessage(error.message);
  };
}

export default new TopListStore();

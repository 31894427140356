import { observable, action, makeAutoObservable } from 'mobx';
import api from './requestWithAuth';

export const TimePeroidTypes = ['1d', '5d', '10d', '20d'];

export const StockTypes = ['KLGD', 'GTGD'];

export interface CodeWatchListProps {
  action: string;
  watchlistID: number;
  stockCode: string;
  onCodeChange: (wlData?: WatchListData) => void;
}

export interface RRGWatchListProps {
  watchListData: Array<WatchListData>;
  loadData: () => void;
  updateWatchListData: (data: Array<WatchListData>) => void;
  codeToWatchListAction: (props: CodeWatchListProps) => void;
  watchListAction: (
    action: string,
    watchlistID: number,
    watchlistName: string,
  ) => void;
  sortActionAction: (action: number, watchlistID: number) => void;
  loading: boolean;
  actionLoading: boolean;
}

export interface WatchListData {
  id: number;
  name: string;
  codes: WatchListCode[];
}
export interface WatchListCode {
  code: string;
  name: string;
  type: number;
  adClose: number;
  adChange: number;
  pctChange: number;
  statusAdClose?: 0 | 1 | 2;
  statusAdChange?: 0 | 1 | 2;
  statusPctChange?: 0 | 1 | 2;
}

class RRGWatchListStore implements RRGWatchListProps {
  private apiPath: string = 'api/watchlist';
  private apiPathWatchList: string = 'api/watchlist/cat';
  private apiPathVnindex: string = 'api/rrg/vnindex';

  @observable
  watchListData: Array<WatchListData> = [];

  @observable
  loading: boolean = false;

  @observable
  actionLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public loadData = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPath}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataSuccess)
      .catch(this.fetchDataError);
  };

  @action
  public loadDataVNIndex = async () => {
    this.loading = true;
    const queryString: string = `${this.apiPathVnindex}`;
    await api
      .get(queryString)
      .then((resp) => resp.data)
      .then(this.fetchDataVNIndexSuccess)
      .catch(this.fetchDataError);
  };

  @action
  private fetchDataSuccess = (dataR: any) => {
    this.watchListData = dataR;
    this.loading = false;
    this.actionLoading = false;
  };

  @action
  private fetchDataVNIndexSuccess = (dataR: any) => {
    this.loading = false;
  };

  @action
  public updateWatchListData = (data: Array<WatchListData>) => {
    this.watchListData = data;
  };

  @action
  public codeToWatchListAction = (props: CodeWatchListProps) => {
    const { action, watchlistID, stockCode, onCodeChange } = props;
    if (action === 'CREATE') {
      const _code = { id: watchlistID, code: stockCode };
      this.loading = true;
      this.actionLoading = true;
      const queryString: string = `/${this.apiPath}`;
      api
        .post(queryString, _code)
        .then((resp) => resp.data)
        .then(this.fetchDataSuccess)
        .then(() =>
          onCodeChange(
            this.watchListData.find((d: any) => d.id === watchlistID),
          ),
        )
        .catch(this.fetchDataError);
    } else if (action === 'DELETE') {
      const _code = { id: watchlistID, code: stockCode };
      this.loading = true;
      this.actionLoading = true;
      const queryString: string = `/${this.apiPath}`;
      api
        .delete(queryString, { data: _code })
        .then((resp) => resp.data)
        .then(this.fetchDataSuccess)
        .then(() =>
          onCodeChange(
            this.watchListData.find((d: any) => d.id === watchlistID),
          ),
        )
        .catch(this.fetchDataError);
    }
  };

  @action
  public watchListAction = (
    action: string,
    watchlistID: number,
    watchlistName: string,
  ) => {
    if (action === 'CREATE') {
      if (watchlistName === '') return;
      const _code = { name: watchlistName };
      this.loading = true;
      this.actionLoading = true;
      const queryString: string = `/${this.apiPathWatchList}`;
      api
        .post(queryString, _code)
        .then((resp) => resp.data)
        .then(this.fetchDataSuccess)
        .catch(this.fetchDataError);
    } else if (action === 'DELETE') {
      if (watchlistID < 1) return;
      const _code = { id: watchlistID };
      this.loading = true;
      this.actionLoading = true;
      const queryString: string = `/${this.apiPathWatchList}`;
      api
        .delete(queryString, { data: _code })
        .then((resp) => resp.data)
        .then(this.fetchDataSuccess)
        .catch(this.fetchDataError);
    } else if (action === 'UPDATE') {
      if (watchlistID < 1) return;
      const _code = { id: watchlistID, name: watchlistName };
      this.loading = true;
      this.actionLoading = true;
      const queryString: string = `/${this.apiPathWatchList}`;
      api
        .put(queryString, _code)
        .then((resp) => resp.data)
        .then(this.fetchDataSuccess)
        .catch(this.fetchDataError);
    }
  };

  @action
  public sortActionAction = (action: number, watchlistID: number) => {
    if (action === 1) {
      this.watchListData
        .find((d: any) => d.id === watchlistID)
        ?.codes.sort((a, b) => a.name.localeCompare(b.name));
    } else if (action === 2) {
      this.watchListData
        .find((d: any) => d.id === watchlistID)
        ?.codes.sort((a, b) => a.pctChange - b.pctChange);
    } else {
      this.watchListData
        .find((d: any) => d.id === watchlistID)
        ?.codes.sort((a, b) => b.pctChange - a.pctChange);
    }
  };

  @action
  private fetchDataError = (error: any) => {
    // FIXME
    //console.error(error);
    this.loading = false;
    this.actionLoading = false;
  };
}

export default new RRGWatchListStore();
